import React from 'react';
import Lottie from 'lottie-react';
import dancingHouse from '../../animations/dancingHouse.json';

function HouseBuild() {
  return (
    <div className="lottie-container">
      <Lottie
        animationData={dancingHouse}
        loop={true}
        autoplay={true}
        style={{ width: '100%', height: '100%', display: 'block' }}
      />
    </div>
  );
}

export default HouseBuild;
