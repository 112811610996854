import React from 'react';

const AppleGoogleButtons = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '24px',
        flexWrap: 'wrap',
      }}
    >
      <div
        style={{
          padding: '10px',
          boxSizing: 'border-box',
        }}
      >
        <a href="https://apps.apple.com/us/app/digs-company/id6446292864?itsct=apps_box_badge&amp;itscg=30200">
          <img
            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1678924800"
            alt="Download on the App Store"
            style={{ width: '150px', height: '50px' }}
          />
        </a>
      </div>

      <div
        style={{
          padding: '10px',
          boxSizing: 'border-box',
        }}
      >
        <a href="https://play.google.com/store/apps/details?id=com.digs.company&pli=1&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
          <img
            alt="Get it on Google Play"
            src="/images/get-it-on-google-play-badge.png"
            style={{ width: '150px', height: '50px' }}
          />
        </a>
      </div>
    </div>
  );
};

export default AppleGoogleButtons;
