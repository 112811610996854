import { useNavigate } from 'react-router-dom';
import { useCustomerDispatch } from '../common/customerContext';
import { PAGES } from '../constants';

const useCustomerNavigation = () => {
  const navigation = useNavigate();
  const dispatch = useCustomerDispatch();
  return (route, action, model, routeAction) => {
    console.log('route', { route, action, model, routeAction });
    dispatch(action, model);
    const routeKey = route.startsWith('/') ? route.substring(1) : route;
    let redirectionRoute = `/${routeKey}`;
    if (routeAction) {
      const newRoute = routeAction(routeKey, action, model);
      if (newRoute?.length > 0) {
        redirectionRoute = newRoute;
      }
    }
    navigation(redirectionRoute);
  };
};

export default useCustomerNavigation;
