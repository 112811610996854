import React from 'react';
import { Row, Col, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const CartNotFound = ({ setPage, selectedAddress }) => {
  const navigate = useNavigate();

  const startOver = () => {
    navigate('/');
  };

  return (
    <Row
      gutter={[]}
      type="flex"
      justify={'center'}
      style={{
        textAlign: 'center',
      }}
    >
      <Col span={24} style={{ textAlign: 'center' }}>
        <img src="/images/digs_logo_color.png" width="80" />
      </Col>

      <Col span={24} style={{ marginTop: '16px' }}>
        <div
          style={{
            display: 'inline-block',
            width: '100%',
            position: 'relative',
          }}
        >
          <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
            We couldn't locate your cart.
          </span>
          <div
            style={{
              fontWeight: 400,
              marginTop: '8px',
              width: '100%',
              wordWrap: 'break-word',
            }}
          >
            Would you like to start over?
          </div>
        </div>
      </Col>
      <Col span={24} style={{ marginTop: 4 }}>
        <Button
          type="primary"
          className="main-button"
          style={{ maxWidth: '400px' }}
          onClick={startOver}
        >
          Yes
        </Button>
      </Col>
    </Row>
  );
};

export default CartNotFound;
