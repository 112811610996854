import { createContext, useContext } from 'react';

// Create a new context for the container
export const ContainerContext = createContext();
// Define a component that provides the container to its children
export const ContainerProvider = ({ container, children }) => {
  return (
    <ContainerContext.Provider value={container}>
      {children}
    </ContainerContext.Provider>
  );
};
// Define a hook to access the container from within a component
export const useInject = () => {
  const container = useContext(ContainerContext);
  if (!container) {
    throw new Error(
      'Container not found. Make sure to wrap your components with a ContainerProvider.',
    );
  }
  return container;
};
