import React from 'react';
import AddStripeCard from './AddStripeCard';
//import "./Content/styles.css";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

export const AddCard = ({
  onSuccess,
  onError,
  disabled,
  buttonText,
  amount,
}) => {
  return (
    <Elements stripe={stripePromise}>
      <AddStripeCard
        onError={onError}
        disabled={disabled}
        afterSaveAction={onSuccess}
        buttonText={buttonText}
        amount={amount}
      />
    </Elements>
  );
};
