import React from 'react';
import './serviceAddress.css';
import { useCustomer } from '../../common/customerContext';
function ServiceAddress({ displayType = 'boxy' }) {
  const customer = useCustomer();
  const { address } = customer;
  const formatAddress = (c) => {
    return `${address?.street} ${address?.city}, ${address?.state}, ${address?.zip}`;
  };

  function boxy() {
    return (
      <div className="sa_boxy">
        <div className="sa_boxy_label">Service Address</div>
        <div>{formatAddress()}</div>
      </div>
    );
  }
  function inline() {
    return <span className="sa_inline-address">{formatAddress()}</span>;
  }
  function formatted() {
    return (
      <address className="sa_formatted">
        {address.street}
        <br></br>
        {address.city}, {address.state}, {address.zip}
      </address>
    );
  }

  return displayType === 'boxy'
    ? boxy()
    : displayType === 'formatted'
    ? formatted()
    : inline();
}

export default ServiceAddress;
