import React from 'react';

const EssentialTag = ({ text }) => {
  const styles = {
    container: {
      border: 'solid 1px #00B47D',
      color: '#00B47D',
      fontSize: '10px',
      padding: '4px 12px',
      borderRadius: '20px',
      textTransform: 'uppercase',
      alignSelf: 'flex-start',
      textAlign: 'center',
      marginRight: '8px',
      background: 'white',
    },
  };

  return (
    <div style={styles.container}>
      <span style={styles.text}>{text}</span>
    </div>
  );
};

export default EssentialTag;
