const sumPlan = (plan) => {
  return plan.reduce((acc, curr) => {
    //ensure both values are numeric.
    let a = isNaN(acc) ? 0 : acc;
    let c = isNaN(curr?.price) ? 0 : curr.price;
    return a + c;
  }, 0);
};

export default sumPlan;
