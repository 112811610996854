import { Button, Card, Col, Row } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import React, { Fragment, useState, useEffect } from 'react';
import DisplayServices from '../Components/ServicesConfig/DisplayServices';
import PriceDisplay from '../Components/Price/PriceDisplay';
import { useCustomer } from '../common/customerContext';
import { PAGES } from '../constants';
import useCustomerNavigation from '../Hooks/NavigationHook';
import Transition from './Transition';
import ProgressHeader from '../Components/Styled/ProgressHeader';
import { CUSTOMER_ACTIONS } from '../constants';
import sumPlan from '../common/sumPlan';
import { ServiceFilter } from '../common';
import useUpdateCurrentPage from '../Hooks/CurrentPage';

const Review = ({ back, onComplete }) => {
  const customer = useCustomer();
  const customerNavigation = useCustomerNavigation();
  const completionPercentage = 75;
  const [showBuildingPlan, setShowBuildingPlan] = useState(
    customer.showBuildingPlan,
  );
  const serviceFilter = new ServiceFilter();

  useUpdateCurrentPage()

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowBuildingPlan(false);
      customer.showBuildingPlan = false;
    }, 4250);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (showBuildingPlan) {
    return (
      <Transition
        mainText={'Hang Tight'}
        helpText1={"We're putting together your"}
        helpText2={'custom annual maintenance plan'}
      />
    );
  }

  const selectedServices = serviceFilter.getSelectedServices(
    customer.services,
    true,
  );
  if (!selectedServices) {
    return null;
  }
  const price = sumPlan(selectedServices) / 12;
  const readyServices = serviceFilter.getReadyServices(customer.services, true);
  const consultationServices = serviceFilter.getConsultationServices(
    customer.services,
    true,
  );
  const showBackIcon = () => {
    return !(customer.meetCode?.length > 1);
  };
  return (
    <Fragment>
      <Row style={{ backgroundColor: '#F7F6F2' }}>
        <ProgressHeader
          title="Looking Good"
          back={'hello'}
          completionPercentage={completionPercentage}
          hasNav={true}
          showBackIcon={showBackIcon()}
        />
        <Col xs={0} md={5} lg={6}></Col>
        <Col
          xs={24}
          md={14}
          lg={12}
          style={{ marginTop: '16px' }}
          className="padMobile"
        >
          <PriceDisplay price={price}></PriceDisplay>
        </Col>
        <Col xs={0} md={5} lg={6}></Col>

        <Col xs={0} md={5} lg={6}></Col>
        <Col
          xs={24}
          md={14}
          lg={12}
          style={{ marginTop: '16px' }}
          className="padMobile"
        >
          <Card
            title={<span>Plan Details</span>}
            extra="Monthly"
            headStyle={{
              backgroundColor: '#DAD6CC',
              paddingLeft: '12px',
              paddingRight: '12px',
            }}
            bodyStyle={{ padding: '0px' }}
            style={{ border: '1px solid #f0f0f0' }}
          >
            <DisplayServices services={readyServices} />
          </Card>
        </Col>
        <Col xs={0} md={5} lg={6}></Col>

        {consultationServices && consultationServices.length > 0 && (
          <Fragment>
            <Col xs={0} md={5} lg={6}></Col>
            <Col
              xs={24}
              md={14}
              lg={12}
              style={{ marginTop: '16px', marginBottom: '16px' }}
              className="padMobile"
            >
              <Card
                title={
                  <span>
                    <WarningFilled style={{ marginRight: '8px' }} />
                    Added After Consultation
                  </span>
                }
                extra="Monthly"
                headStyle={{
                  backgroundColor: '#F6F6F6',
                  paddingLeft: '12px',
                  paddingRight: '12px',
                }}
                bodyStyle={{ padding: '0px' }}
                style={{ border: '1px solid #f0f0f0' }}
              >
                <div
                  style={{
                    fontStyle: 'italic',
                    padding: '16px',
                    opacity: '0.6',
                    textAlign: 'center',
                  }}
                >
                  The following services require a consultation visit from the
                  vendor before prices can be provided.
                </div>
                <hr style={{ width: '100%', border: '1px solid #f0f0f0' }} />
                <div style={{ padding: '0px 0' }}>
                  <DisplayServices services={consultationServices} />
                </div>
              </Card>
            </Col>
            <Col xs={0} md={5} lg={6}></Col>
          </Fragment>
        )}

        <Col xs={0} md={5} lg={6}></Col>
        <Col
          xs={24}
          md={14}
          lg={12}
          style={{
            marginBottom: '32px',
            textAlign: 'center',
            marginTop: '16px',
          }}
          className="padMobile"
        >
          <Button
            className="main-button"
            style={{ marginBottom: '64px' }}
            type="primary"
            onClick={() =>
              customerNavigation(
                PAGES.PAYMENT,
                CUSTOMER_ACTIONS.PAYMENT,
                customer,
              )
            }
          >
            Next
          </Button>
        </Col>
        <Col xs={0} md={5} lg={6}></Col>
      </Row>
    </Fragment>
  );
};

export default Review;
