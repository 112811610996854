import { PriceItem } from '../../../../common/PriceItem';

const multiSelectDataMapper = (resultService, data) => {
  let perYear = data.perYear;
  if (data.perYear) {
    delete data.perYear;
    resultService.perYear = perYear;
  }
  resultService.selectedUnits = data.selectedUnits
    .filter((d) => d)
    .map((d) => {
      const area = resultService.options.areas.find((a) => a.room === d.room);
      const unit = area.selectors.find((s) => s.unit === d.unit);
      const mappedService = { room: d.room, ...unit };
      return mappedService;
    });

  const newService = new PriceItem(resultService);
  return newService;
};

export default multiSelectDataMapper;
