import React, { Fragment, useState } from 'react';
import { Button, Card, Form, InputNumber, Select } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import Counter from '../../../Styled/Counter';

const MultiAreaInput = ({ service, form }) => {
  const { options, infoBlurb, question, name } = service;
  const { areas } = options;

  const [error, setError] = useState(undefined);

  const validateInputs = (_, value) => {
    const { selectedUnits } = form.getFieldsValue();
    const isValid = areas.some((a, index) => {
      return selectedUnits[index].quantity > 0;
    });
    if (isValid) {
      setError(undefined);
      return Promise.resolve();
    } else {
      setError('Choose at least 1 area');
      return Promise.reject('Choose at least 1 area');
    }
  };
  return (
    <Fragment>
      {areas.map((a, index) => {
        return (
          <Card
            key={a.name}
            size="small"
            className="light-gray-card"
            style={{ marginBottom: '8px', textAlign: 'left' }}
            title={
              <>
                {a.label}
                {a.info != undefined && (
                  <span
                    style={{
                      marginLeft: '4px',
                      fontSize: '12px',
                      opacity: '.6',
                    }}
                  >
                    {a.info}
                  </span>
                )}
              </>
            }
          >
            <Form.Item
              noStyle
              name={['selectedUnits', index, 'quantity']}
              rules={[{ validator: validateInputs }]}
              trigger="onChange"
            >
              <Counter validation={false} />
            </Form.Item>
          </Card>
        );
      })}
      {error && <div style={{ color: 'red' }}>{error}</div>}
    </Fragment>
  );
};

export default MultiAreaInput;
