import { Button, Col, Row, Form, Input, Card } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useForm } from 'antd/es/form/Form';
import { loadStripe } from '@stripe/stripe-js';
import { AddCard } from '../Components/Stripe/AddCard';
import { ServiceFilter, useInject } from '../common';
import ServiceAddress from '../Components/Address/serviceAddress';
import PriceDisplay from '../Components/Price/PriceDisplay';
import { useCustomer } from '../common/customerContext';
import ProgressHeader from '../Components/Styled/ProgressHeader';
import { CUSTOMER_ACTIONS, PAGES } from '../constants';
import useCustomerNavigation from '../Hooks/NavigationHook';
import CustomerForm from '../Components/customerForm';
import { CardElement } from '@stripe/react-stripe-js';
import { to } from 'react-spring';
import sumPlan from '../common/sumPlan';
import Transition from './Transition';
import useUpdateCurrentPage from '../Hooks/CurrentPage';

const Payment = ({ services, back, onComplete }) => {
  const [form] = useForm();
  const customer = useCustomer();
  const customerNavigation = useCustomerNavigation();
  const { logger, selfSignupCustomerService, communicationService } =
    useInject();
  const [serviceIndex, setServiceIndex] = useState(0);
  const completionPercentage = 100;
  const [isContentLong, setIsContentLong] = useState(false);
  const [showTransition, setShowTransition] = useState(false);

  useUpdateCurrentPage()

  if (showTransition) {
    return (
      <Transition
        mainText={'Processing your payment'}
        helpText1={'Please do not refresh the page.'}
      />
    );
  }

  const cardSuccess = async (token) => {
    customer.paymentToken = token;
    setShowTransition(true);
    await selfSignupCustomerService.saveCustomerProgress(customer);
    await selfSignupCustomerService.completeCustomerProgress(customer);
    await communicationService.sendWelcomeEmail(customer);
    await communicationService.sendSmsMessage(customer);
    customerNavigation(PAGES.SUCCESS, CUSTOMER_ACTIONS.COMPLETE, customer);
  };
  const cardFailure = () => {};

  const selectedServices = new ServiceFilter().getSelectedServices(
    customer.services,
    true,
  );
  const price = sumPlan(selectedServices) / 12;

  return (
    <Fragment>
      <Row type="flex" justify="center" style={{ backgroundColor: '#F7F6F2' }}>
        <ProgressHeader
          title="One Last Step!"
          back={'hello'}
          completionPercentage={completionPercentage}
          hasNav={true}
        />

        <Row gutter={[0, 16]}>
          <Col xs={0} md={5} lg={6}></Col>
          <Col
            xs={24}
            md={14}
            lg={12}
            style={{ marginTop: '16px' }}
            className="padMobile"
          >
            <PriceDisplay price={price}></PriceDisplay>
          </Col>
          <Col xs={0} md={5} lg={6}></Col>

          {/* 
          <Col xs={0} md={5} lg={6}></Col>
          <Col
            xs={24} md={14} lg={12}
            style={{ fontSize: "14px",marginTop:"16px",marginBottom:"16px", fontWeight: 600 }}
            className="padMobile"
          >
            Payment Information
          </Col>
          <Col xs={0} md={5} lg={6}></Col> */}

          <Col xs={0} md={5} lg={6}></Col>
          <Col
            xs={24}
            md={14}
            lg={12}
            style={{ fontSize: '14px', marginTop: '16px', fontWeight: 500 }}
            className="padMobile"
          >
            <AddCard
              onSuccess={cardSuccess}
              onError={cardFailure}
              amount={price}
            />
          </Col>
          <Col xs={0} md={5} lg={6}></Col>
        </Row>
      </Row>
    </Fragment>
  );
};

export default Payment;
