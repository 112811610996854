import { PriceItem } from '../../../../common/PriceItem';

const multiAreaInputDataMapper = (resultService, data) => {
  let perYear = data.perYear;
  if (data.perYear) {
    delete data.perYear;
    resultService.perYear = perYear;
  }
  resultService.selectedUnits = data.selectedUnits.map((d, index) => {
    const area = {
      ...resultService.options.areas[index],
      quantity: d.quantity,
    };
    return area;
  });

  const newService = new PriceItem(resultService);
  return newService;
};

export default multiAreaInputDataMapper;
