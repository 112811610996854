const isDebug = process.env.REACT_APP_LOGGING_DEBUG;
export class ConsoleLogger {
  constructor(logPrefix) {
    if (logPrefix) {
      this.logPrefix = `[${logPrefix}]: `;
    }
  }

  get log() {
    if (!isDebug) {
      return () => {};
    }
    const logPrefix = this.logPrefix;
    if (logPrefix.length) {
      return console.log.bind(window.console, logPrefix);
    } else {
      return console.log.bind(window.console);
    }
  }

  get warn() {
    if (!isDebug) {
      return () => {};
    }
    const logPrefix = this.logPrefix;
    if (logPrefix.length) {
      return console.warn.bind(window.console, logPrefix);
    } else {
      return console.warn.bind(window.console);
    }
  }

  get error() {
    if (!isDebug) {
      return () => {};
    }
    const logPrefix = this.logPrefix;
    if (logPrefix.length) {
      return console.error.bind(window.console, logPrefix);
    } else {
      return console.error.bind(window.console);
    }
  }
}
