import React, { useRef, useEffect, useState } from 'react';
import { Row, Col, Button, Input, Form, Alert } from 'antd';
import { useInject } from '../common';
import ServiceAddress from '../Components/Address/serviceAddress';
import { useCustomer } from '../common/customerContext';
import { CUSTOMER_STATUS } from '../constants';
import ProgressHeader from '../Components/Styled/ProgressHeader';

const OutOfArea = ({ setPage, selectedAddress }) => {
  const { selfSignupCustomerService } = useInject();
  const customer = useCustomer();

  const spanRef = useRef(null);
  const [form] = Form.useForm();
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    if (spanRef.current) {
      const width = spanRef.current.offsetWidth;
      spanRef.current.parentNode.style.width = `${width}px`;
    }
  }, []);

  const onFinish = async (formValues) => {
    const selfSignupCustomer = {
      emailAddress: formValues.emailAddress,
      status: CUSTOMER_STATUS.WAITLIST,
      address: customer?.formattedAddress,
      placeId: customer.placeId,
      referralCode: customer?.referralCode,
      publicData: customer?.publicData,
    };
    await selfSignupCustomerService.addToWaitlist(selfSignupCustomer);
    setShowConfirmation(true);
  };

  return (
    <Row
      gutter={[]}
      type="flex"
      justify={'center'}
      style={{
        textAlign: 'center',
      }}
    >
      <Col xs={24} style={{ textAlign: 'center' }}>
        <ProgressHeader
          hasNav={false}
          showProgressBar={false}
          showHelp={false}
        />
      </Col>

      <Col span={24} style={{ marginTop: '16px' }}>
        <div
          style={{
            display: 'inline-block',
            width: '100%',
            position: 'relative',
          }}
        >
          <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
            Digs isn't available for this area.
          </span>
          <div
            style={{
              fontWeight: 400,
              marginTop: '8px',
              width: '100%',
              wordWrap: 'break-word',
            }}
          >
            Enter your email address to get information about Digs
          </div>
        </div>
      </Col>

      <Form form={form} name="outOfAreaForm" onFinish={onFinish}>
        <Row
          type="flex"
          justify={'center'}
          style={{
            marginRight: 8,
            marginLeft: 8,
            textAlign: 'center',
            marginTop: '16px',
          }}
        >
          <Col xs={24} md={18} lg={16}>
            <Form.Item
              className="custom-form-item"
              name="emailAddress"
              rules={[
                { required: true, message: 'Please input your email' },
                {
                  pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: 'Please enter a valid email address',
                },
              ]}
            >
              <Input
                placeholder="Your email address"
                type="email"
                className="bulky-input"
                disabled={showConfirmation}
              />
            </Form.Item>
          </Col>
          {!showConfirmation && (
            <Col
              span={24}
              style={{
                textAlign: 'center',
                marginBottom: '16px',
                marginTop: '8px',
              }}
            >
              <Button type="primary" className="main-button" htmlType="submit">
                Get Updates
              </Button>
            </Col>
          )}

          {showConfirmation && (
            <Col
              xs={24}
              md={18}
              lg={16}
              style={{ textAlign: 'center', marginBottom: '16px' }}
            >
              <Alert
                message="Thank you! We'll let you know once we're in your area"
                className="digs-message-box"
              />
            </Col>
          )}

          <Col span={24} style={{ textAlign: 'center', marginBottom: '32px' }}>
            <ServiceAddress
              displayType="inline"
              address={selectedAddress}
            ></ServiceAddress>
            <div style={{ marginTop: '4px' }}>
              <a href="/">Check another address</a>
            </div>
          </Col>
        </Row>
      </Form>
    </Row>
  );
};

export default OutOfArea;
