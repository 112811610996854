import React, { useState } from 'react';
import { Layout, Button, Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { formatMoney } from '../../Helpers/formatters';
import CustomerForm from '../customerForm';
import { CUSTOMER_ACTIONS } from '../../constants';
import { useCustomer } from '../../common/customerContext';

const Content = Layout.Content;
const AddStripeCard = ({
  afterSaveAction,
  buttonText,
  disabled: validationDisable,
  amount = 0,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { firstName, lastName, phoneNumber, emailAddress } = useCustomer();
  const [disabled, setDisabled] = useState(false);
  const [form] = useForm();
  const initialValues = {
    firstName,
    lastName,
    phoneNumber,
    emailAddress,
  };
  const submit = async (user) => {
    try {
      const cardElement = elements.getElement(CardElement);
      setDisabled(true);
      let token;
      const result = await stripe.createToken(cardElement);
      token = result.token;
      cardElement.clear();
      if (!token) {
        setDisabled(false);
        return;
      }

      await afterSaveAction(token);
    } catch (error) {
      console.log('failed ');
      console.log(error);
    }

    setDisabled(false);
  };

  const formSubmit = (user) => {
    submit(user);
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={formSubmit}
      onFinishFailed={(e) => console.log(e)}
      initialValues={initialValues}
    >
      <CustomerForm action={CUSTOMER_ACTIONS.PAYMENT}></CustomerForm>
      <div style={{ fontWeight: 400, marginBottom: '4px', marginTop: '-8px' }}>
        Payment Information
      </div>
      <CardElement
        options={{
          disableLink: true,
        }}
        className="bulky-input stripe-antd"
        style={{
          base: {
            color: '#32325d',
            background: 'white',
            fontFamily: 'Lato, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '10px',
            '::placeholder': {
              color: '#aab7c4',
              fontFamily: 'Lato, sans-serif',
              fontSize: '10px',
            },
          },
          invalid: {
            color: '#fa755a',
            iconColor: '#ff4d4f',
            borderColor: '#ff4d4f',
          },
        }}
      />
      <Content style={{ textAlign: 'center', paddingTop: '20px' }}>
        <div style={{ textAlign: 'center' }}>
          By clicking "Agree & Pay" you agree to{' '}
          <a href="https://digs.company/terms/"> Dig's Terms and Conditions </a>
          and are signing up for a <b>Digs Annual Maintenance Plan </b>
          with recurring monthly payments of {formatMoney(amount)}
        </div>

        <Button
          className="main-button"
          htmlType="submit"
          loading={disabled}
          type={'primary'}
          disabled={validationDisable}
          style={{ marginTop: '48px', marginBottom: '64px' }}
        >
          {buttonText ? buttonText : `Agree & Pay ${formatMoney(amount)}`}
        </Button>
      </Content>
    </Form>
  );
};

export default AddStripeCard;
