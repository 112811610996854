import { HOUSE_WASH_PRICE } from '../constants';

export class PriceItem {
  constructor({
    name,
    color,
    unitLabel,
    perYear,
    options,
    location,
    defaultAdded = true,
    id,
    info,
    serviceId,
    parentId,
    note,
    icon,
    order,
    version,
    selectedUnits,
    infoBlurb,
    question,
    tshirtSize,
  }) {
    this.order = order;
    this.id = id;
    this.name = name;
    this.color = color;
    this.unitLabel = unitLabel;
    this.perYear = perYear;
    this.defaultAdded = defaultAdded;
    this.included = defaultAdded;
    this.location = location;
    this.options = options;
    this.selectedUnit = this.getDefaultSelectedUnit();
    this.info = info;
    this.serviceId = serviceId;
    this.parentId = parentId;
    this.note = note;
    this.icon = icon;
    this.version = version;
    this.selectedUnits = selectedUnits;
    this.infoBlurb = infoBlurb;
    this.question = question;
    this.tshirtSize = tshirtSize;
  }

  getDefaultSelectedUnit(defaultSelects) {
    if (!this.options) {
      return undefined;
    }
    if (this.options.type.toLowerCase() === 'select' && defaultSelects) {
      return { ...this.options.selectors.find((s) => s.default) };
    }
    if (this.options.type.toLowerCase() === 'select') {
      return;
    }
    if (this.options.type.toLowerCase() === 'multiselect') {
      return;
    }
    if (this.options.type.toLowerCase() === 'multiple-area-input') {
      return;
    }
    if (this.options.type === 'none' || this.options.type === 'custom') {
      return {
        price: this.options.price,
        unit: 'individual',
        quantity: 1,
        info: this.options?.info,
      };
    }

    return {
      price: this.options.price,
      unit: 'individual',
      quantity: 1,
      info: this.options?.info,
    };
  }
  clone() {
    return PriceItem.mergeSavedService({ ...this });
  }
  setSelectedUnit(unit) {
    this.selectedUnit = unit;
  }
  setTShitSize(size, exactPropertySize, propertySizeChangedCount) {
    if (this.options.type === 'select') {
      let unit;

      // Specific to House Wash
      // Use exact sq ft if available
      if (
        this.name === 'House Wash' &&
        exactPropertySize > 0 &&
        (propertySizeChangedCount <= 0 ||
          propertySizeChangedCount === undefined)
      ) {
        this.options.type = 'none';
        this.options.selectors.map((s) => {
          if (s.tshirtSize === 'exact') {
            s.info = `${exactPropertySize.toLocaleString()} sq ft`;
            s.propertySize = exactPropertySize;
            s.price = HOUSE_WASH_PRICE * exactPropertySize;
          }
          return s;
        });
        unit = this.options.selectors.find((s) => 'exact' === s.tshirtSize);
      } else {
        unit = this.options.selectors.find((s) => s.tshirtSize === size);
      }

      //if for some reason an option doesn't have a matching tshirt size, we will simply
      //select the last item in the list.
      if (unit === undefined) {
        unit = this.options.selectors[this.options.selectors.length - 1];
      }

      this.selectedUnit = { ...unit };
    } else if (
      (this.options.type === 'number' || this.options.type === 'none') &&
      this.options.tshirtSizes &&
      this.tshirtSize
    ) {
      //handle other tshirt sizes
      let unit = this.options.tshirtSizes.find((s) => s.tshirtSize === size);
      //if for some reason an option doesn't have a matching tshirt size, we will simply
      //select the last item in the list.
      if (unit === undefined) {
        unit = this.options.tshirtSizes[this.options.selectors.length - 1];
      }
      this.selectedUnit = { ...this.options, ...unit };
    }
  }
  isValid() {
    //this will set if the service has an error to true and return false for being valid
    if (!this.included) {
      this.hasError = false;
      return true;
    } else if (this.location === 'constant') {
      this.hasError = false;
      return true;
    } else if (!this.name || this.price === undefined || this.price === '') {
      this.hasError = true;
      return false;
    } else if (this.options.type === 'select') {
      const hasError = this.perYear && this.selectedUnit;
      this.hasError = !hasError;
      return !!hasError;
    } else if (this.options.type === 'none' || this.options.type === 'custom') {
      const hasError = this.perYear && this.selectedUnit?.quantity;
      this.hasError = !hasError;
      return !!hasError;
    } else if (
      this.options.type === 'multiSelect' ||
      this.options.type === 'multiple-area-input'
    ) {
      const hasError = !!this.price;
      this.hasError = !!hasError;
      return !!hasError;
    } else {
      const hasError =
        this.selectedUnit && this.perYear && this.selectedUnit.quantity;
      this.hasError = !hasError;
      return !!hasError;
    }
  }
  mergeSavedService({
    name,
    color,
    perYear,
    selectedUnit,
    included,
    unitLabel,
    defaultAdded,
    location,
    options,
    id,
    vendor,
    notes,
    status,
    scheduledDate,
    info,
    serviceId,
    parentId,
    note,
    icon,
    order,
    version,
    selectedUnits,
    infoBlurb,
    question,
  }) {
    this.version = version;
    this.order = order;
    this.id = id;
    this.name = name;
    this.color = color;
    this.perYear = perYear;
    this.defaultAdded = included;
    this.included = included;
    this.selectedUnit = selectedUnit;
    this.unitLabel = unitLabel;
    this.location = location;
    this.options = options;
    this.vendor = vendor;
    this.notes = notes;
    this.status = status;
    this.scheduledDate = scheduledDate;
    this.info = info;
    this.serviceId = serviceId;
    this.parentId = parentId;
    this.note = note;
    this.icon = icon;
    this.selectedUnits = selectedUnits;
    this.infoBlurb = infoBlurb;
    this.question = question;
  }

  calculation = (q, py, selectedUnit) => {
    const minPrice = this.options?.min || 0;

    const hasPriceTiers = this.options?.priceTiers;
    const selected = selectedUnit ? selectedUnit : this.selectedUnit;

    if (!hasPriceTiers) {
      return q * selected.price * py;
    }
    const priceTiers = [...this.options.priceTiers];
    let value = priceTiers.shift();
    let price = 0;
    for (let index = 0; index < q; index++) {
      price += value;

      if (priceTiers.length > 0) {
        value = priceTiers.shift();
      }
    }
    const result = price * py;
    return Math.max(result, minPrice);
  };

  static mergeSavedService(item) {
    const pi = new PriceItem({});
    pi.mergeSavedService(item);
    return pi;
  }
  get basePrice() {
    return this.calculation(1, 1, this.getDefaultSelectedUnit(true));
  }
  get price() {
    let quantity = this.selectedUnit?.quantity || 1;

    if (!this.selectedUnit && !this.selectedUnits) {
      return 0;
    }

    if (this.selectedUnit === 'individual') {
      return this.calculation(quantity, this.perYear, this.getSelectedUnit());
    }

    return this.calculation(
      quantity || 0,
      this.perYear,
      this.getSelectedUnit(),
    );
  }
  applyModifier = (initialValue, modifierInfo) => {
    let price = initialValue;
    if (modifierInfo.type === 'multiplier') {
      price = price * modifierInfo.value;
    } else if (modifierInfo.type === 'addition') {
      price += modifierInfo.value;
    } else if (modifierInfo.type === 'subtraction') {
      price -= modifierInfo.value;
    }
    return price;
  };
  getSelectedUnit = () => {
    if (this.selectedUnit) {
      return this.selectedUnit;
    }
    if (this.selectedUnits) {
      const sums = (acc, value) => {
        return acc + value;
      };
      return {
        quantity:
          this.selectedUnits.reduce((acc, unit) => {
            return acc + (unit.quantity || 0);
          }, 0) || 1,

        price: this.selectedUnits.reduce((acc, unit) => {
          let price =
            unit.price * (unit.quantity === undefined ? 1 : unit.quantity);
          // if (unit.modifierInfo) {
          //     const { modifierInfo } = unit
          //     price = this.applyModifier(price, modifierInfo)
          // }
          return sums(acc, price);
        }, 0),
      };
    }
  };
  toJSON() {
    return {
      order: this.order,
      name: this.name,
      color: this.color,
      unitLabel: this.unitLabel,
      perYear: this.perYear,
      defaultAdded: this.defaultAdded,
      included: this.included,
      location: this.location,
      options: this.options,
      selectedUnit: this.selectedUnit,
      price: this.price,
      vendor: this.vendor,
      notes: this.notes,
      status: this.status,
      scheduledDate: this.scheduledDate,
      id: this.id,
      info: this.info,
      serviceId: this.serviceId,
      parentId: this.parentId,
      note: this.note,
      icon: this.icon,
      version: this.version,
      selectedUnits: this.selectedUnits,
      infoBlurb: this.infoBlurb,
      question: this.question,
    };
  }
}
