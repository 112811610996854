import React, { useState, useEffect } from 'react';
import { Row, Col, Checkbox, Button, Form } from 'antd';
import { InfoCircleOutlined, EditOutlined } from '@ant-design/icons';
import ServiceDrawer from '../ServiceDrawer';
import EssentialTag from '../Styled/EssentialTag';
import EssentialInfoDrawer from '../EssentialInfoDrawer';
import { useCustomer, useCustomerDispatch } from '../../common/customerContext';
import ProgressHeader from '../Styled/ProgressHeader';
import { CUSTOMER_ACTIONS } from '../../constants';
import firstBy from 'thenby';
import { generateDisplayText } from '../../Helpers/generateDisplayText';
import { ServiceFilter } from '../../common';
import CustomerForm from '../customerForm';
import { useForm } from 'antd/es/form/Form';
import { useInject } from '../../common';
import useUpdateCurrentPage from '../../Hooks/CurrentPage';


const SelectServices = ({ onComplete, onServiceUpdate }) => {
  const [form] = useForm();
  const serviceFilter = new ServiceFilter();
  const { servicesService, selfSignupCustomerService } = useInject();
  const [isDrawerVisible, setDrawerVisible] = useState(false);
  const [isInfoDrawerVisible, setInfoDrawerVisible] = useState(false);
  const customer = useCustomer();
  const dispatch = useCustomerDispatch();
  const [selectedService, setSelectedService] = useState({});
  const [services, setServices] = useState(customer.services || []);
  const completionPercentage = 50;
  const essentialServices =
    services
      .filter((service) => service.location === 'essential')
      .sort(firstBy('order')) || [];
  const additionalServices =
    services
      .filter((service) => service.location === 'additional')
      .sort(firstBy('order')) || [];
  const [checkedServicesCount, setCheckedServicesCount] = useState(0);

  useEffect(() => {
    const count = services.filter((service) => service.included).length;
    setCheckedServicesCount(count);
  }, [services]);

  const serviceListConfigurationComplete = () => {
    dispatch(CUSTOMER_ACTIONS.UPDATE_SERVICES, services);
    onComplete();
  };

  useUpdateCurrentPage()

  const onServiceChange = (service) => {
    setServices(
      services.map((s) => (s.serviceId === service.serviceId ? service : s)),
    );
    onServiceUpdate(service);
  };

  console.log("CUSTOMER IN SELECT SERVICES IS ", customer);

  const ServiceList = ({ services, childServices }) => {
    return services.map((service) => {
      const children = childServices.filter(
        (s) => s.parentId && s.parentId == service.serviceId,
      );
      return (
        <Col span={24} key={service.serviceId}>
          <label>
            <div
              className="input-box-radio"
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                padding: '12px',
                backgroundColor: 'white',
                // border: '2px solid #DAD6CC',
              }}
              onClick={() => {
                setSelectedService(service);
                toggleDrawer();
              }}
            >
              <Checkbox
                style={{ marginRight: '8px', pointerEvents: 'none' }}
                defaultChecked={service.included}
                onClick={(e) => e.stopPropagation()}
              />
              <div style={{ flex: 1, marginLeft: '10px' }}>
                <div>{service.name}</div>
                <div style={{ opacity: '.6', marginTop: '8px' }}>
                  {service.included
                    ? generateDisplayText(service, children)
                    : null}
                </div>
              </div>
              {service.location === 'essential' && (
                <div>
                  <EssentialTag text={'essential'} />
                </div>
              )}
              <InfoCircleOutlined
                style={{
                  color: '#A2A3A5',
                  alignSelf: 'flex-start',
                  fontSize: '22px',
                  opacity: '0.6',
                }}
                onClick={() => {
                  setSelectedService(service);
                  toggleDrawer();
                }}
              >
                <EditOutlined />
              </InfoCircleOutlined>
            </div>
          </label>
        </Col>
      );
    });
  };

  const toggleDrawer = () => {
    setDrawerVisible(!isDrawerVisible);
  };

  const toggleInfoDrawer = () => {
    setInfoDrawerVisible(!isInfoDrawerVisible);
  };

  const submitForm = async () => {
    try {
      await form.validateFields();
      customer.formFilled = true;
      await selfSignupCustomerService.saveCustomerProgress(customer);
      dispatch(CUSTOMER_ACTIONS.LEADGEN, customer);
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  if (!customer.formFilled) {
    return (
      <div style={{ backgroundColor: '#F7F6F2', height: '100vh' }}>
        <Row type="flex" justify="center" style={{}}>
          <ProgressHeader
            completionPercentage={completionPercentage - 10}
            showBackIcon={true}
            hasNav={true}
            title={'Start Your Journey'}
          />

          <Col
            align="center"
            xs={24}
            md={14}
            lg={12}
            style={{ marginTop: '8px' }}
            className="padMobile"
          >
            <span style={{ marginTop: 8, textAlign: 'center' }}>
              Enter your details below to begin
            </span>
            <Form
              autoComplete="on"
              form={form}
              layout="vertical"
              onFinish={submitForm}
            >
              <div style={{ marginTop: '24px' }} />
              <CustomerForm action={CUSTOMER_ACTIONS.LEADGEN}></CustomerForm>
              <Form.Item>
                <div style={{ textAlign: 'center' }}>
                  <Button
                    className="main-button"
                    style={{ marginBottom: '48px', marginTop: '32px' }}
                    type="primary"
                    htmlType="submit"
                    // disabled={!submittable}
                  >
                    Next
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <Row
      gutter={[0, 16]}
      type="flex"
      justify="center"
      style={{ backgroundColor: '#F7F6F2' }}
    >
      <ProgressHeader
        title="Start with the Essentials"
        back={'hello'}
        completionPercentage={completionPercentage}
        hasNav={true}
      />

      <Col xs={0} md={5} lg={6}></Col>
      <Col
        xs={24}
        md={14}
        lg={12}
        align={'center'}
        style={{}}
        className="padMobile"
      >
        <div style={{ fontWeight: 400, marginTop: '8px' }}>
          Based on your home size and location, we've started you out with our
          <a
            onClick={(e) => {
              e.stopPropagation();
              setInfoDrawerVisible(true);
            }}
          >
            {' '}
            Essential Services.
          </a>{' '}
          Feel free to customize your plan.
        </div>
      </Col>
      <Col xs={0} md={5} lg={6}></Col>

      <Col xs={0} md={5} lg={6}></Col>
      <Col xs={24} md={14} lg={12} style={{}} className="padMobile">
        <ServiceList
          services={essentialServices}
          childServices={services.filter((s) => s.parentId)}
          defaultChecked={true}
        />
      </Col>
      <Col xs={0} md={5} lg={6}></Col>

      <Col xs={0} md={5} lg={6}></Col>
      <Col xs={24} md={14} lg={12} style={{}} className="padMobile">
        <div style={{ fontWeight: 400, textAlign: 'center' }}>
          What other services would you like to add?
        </div>
      </Col>
      <Col xs={0} md={5} lg={6}></Col>

      <Col xs={0} md={5} lg={6}></Col>
      <Col xs={24} md={14} lg={12} style={{}} className="padMobile">
        <ServiceList
          services={additionalServices}
          childServices={services.filter((s) => s.parentId)}
        />
      </Col>
      <Col xs={0} md={5} lg={6}></Col>

      <Col xs={0} md={5} lg={6}></Col>
      <Col
        xs={24}
        md={14}
        lg={12}
        style={{}}
        className="padMobile"
        align={'center'}
      >
        {checkedServicesCount < 5 && (
          <div style={{ color: 'red', marginBottom: '10px' }}>
            4 services required to proceed
          </div>
        )}
        <Button
          style={{ marginBottom: '64px' }}
          type="primary"
          htmlType="submit"
          className="main-button"
          onClick={() => {
            serviceListConfigurationComplete();
          }}
          disabled={checkedServicesCount < 5}
        >
          Next
        </Button>
      </Col>
      <Col xs={0} md={5} lg={6}></Col>

      <ServiceDrawer
        visible={isDrawerVisible}
        onClose={toggleDrawer}
        service={selectedService}
        children={services.filter(
          (s) => s.parentId && s.parentId == selectedService?.serviceId,
        )}
        onComplete={(service) => {
          onServiceChange(service);
          toggleDrawer();
        }}
      />
      <EssentialInfoDrawer
        visible={isInfoDrawerVisible}
        onClose={toggleInfoDrawer}
      />
    </Row>
  );
};


export default SelectServices;
