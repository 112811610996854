export class SelfSignupCustomerService {
  constructor(logger, httpService) {
    this.logger = logger;
    this.httpService = httpService;
  }
  getCustomer = async (id) => {
    try {
      const result = await this.httpService.get(
        `${process.env.REACT_APP_YEARLY_SUBSCRIPTIONS}/selfSignupCustomers?id=${id}&isCartId=true`,
      );
      const d = result.data;
      if (d?.length > 0) {
        return d[0];
      }
      return undefined;
    } catch (error) {
      this.logger?.log(error);
      return undefined;
    }
  };
  getCustomerByPlaceId = async (placeId) => {
    try {
      const result = await this.httpService.get(
        `${process.env.REACT_APP_YEARLY_SUBSCRIPTIONS}/selfSignupCustomers/getByPlaceId?id=${placeId}`,
      );
      //if there are more than one, take the latest
      if (result.data && result.data.length > 1) {
        let data = result.data;
        data = data.sort((a, b) => new Date(b.created) - new Date(a.created));
        return data[0];
      } else {
        return result.data[0];
      }
    } catch (error) {
      this.logger?.log(error);
      return undefined;
    }
  };

  addToWaitlist = async (selfSignupCustomer) => {
    try {
      const result = await this.httpService.post(
        `${process.env.REACT_APP_YEARLY_SUBSCRIPTIONS}/selfSignupCustomers`,
        selfSignupCustomer,
      );
      return result.data;
    } catch (error) {
      this.logger?.log(error);
      return undefined;
    }
  };
  saveCustomerProgress = async (customerModel) => {
    try {
      if (!customerModel.created) {
        customerModel.created = customerModel.updated = new Date();
      } else {
        customerModel.updated = new Date();
      }

      if (customerModel.id !== undefined) {
        console.log('patching customer', customerModel?.id);
        const result = await this.httpService.patch(
          `${process.env.REACT_APP_YEARLY_SUBSCRIPTIONS}/selfSignupCustomers/${customerModel.id}`,
          customerModel,
        );
        return result.data;
      } else {
        console.log('posting customer', customerModel?.id);
        const result = await this.httpService.post(
          `${process.env.REACT_APP_YEARLY_SUBSCRIPTIONS}/selfSignupCustomers`,
          customerModel,
        );
        return result.data;
      }
    } catch (error) {
      this.logger?.log(error);
      return undefined;
    }
  };

  verifySubscriptionStatus = async (placeId) => {
    //existingSubscription
    try {
      const result = await this.httpService.get(
        `${process.env.REACT_APP_YEARLY_SUBSCRIPTIONS}/get-subscription-by-placeid/${placeId}`,
      );
      return result.data;
    } catch (error) {
      this.logger?.log(error);
      return undefined;
    }
  };

  completeCustomerProgress = async (customerModel) => {
    try {
      if (customerModel.id) {
        const result = await this.httpService.post(
          `${process.env.REACT_APP_YEARLY_SUBSCRIPTIONS}/create-selfServe-subscription`,
          { id: customerModel.id },
        );
        return result.data;
      } else {
        throw new Error('id is required');
      }
    } catch (error) {
      this.logger?.log(error);
      return undefined;
    }
  };
}
