import { useMediaQuery } from 'react-responsive';

const MOBILE_BREAKPOINT = 600;
const TABLET_BREAKPOINT = 1024;

export const useMobile = () => {
  return useMediaQuery({ query: `(max-width: ${MOBILE_BREAKPOINT}px)` });
};

export const useTablet = () => {
  return useMediaQuery({
    query: `(min-width: ${
      MOBILE_BREAKPOINT + 1
    }px) and (max-width: ${TABLET_BREAKPOINT}px)`,
  });
};

export const useDesktop = () => {
  return useMediaQuery({ query: `(min-width: ${TABLET_BREAKPOINT + 1}px)` });
};
