import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useUpdateCurrentPage = () => {
  const location = useLocation();

  const updateCurrentPage = () => {
    localStorage.setItem('currentPage', location.pathname);
  };

  useEffect(() => {
      updateCurrentPage();
      
      const currentPage = localStorage.getItem('currentPage');
      console.log("CURRENT PAGE IS :", currentPage);
  }, [location]);

  return updateCurrentPage;
};

export default useUpdateCurrentPage;
