export const antdTheme = {
  token: {
    colorPrimary: '#00B47D',
    colorLink: '#00B47D',
    colorLinkActive: '#00B47D',
    colorLinkHover: '#00B47D',

    colorIcon: '00B47D',
    colorIconHover: '00B47D',
    colorInfo: '00B47D',
  },
};
