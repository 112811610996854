import React, { useRef, useEffect } from 'react';
import { Row, Col } from 'antd';
import ProgressHeader from '../Components/Styled/ProgressHeader';
import AppleGoogleButtons from '../Components/Styled/AppleGoogleButtons';
const PlanExists = () => {
  const spanRef = useRef(null);

  useEffect(() => {
    if (spanRef.current) {
      const width = spanRef.current.offsetWidth;
      spanRef.current.parentNode.style.width = `${width}px`;
    }
  }, []);

  return (
    <Row
      type="flex"
      justify={'center'}
      style={{
        textAlign: 'center',
      }}
    >
      <Col xs={24} style={{ textAlign: 'center' }} className="gutter-row">
        <ProgressHeader
          hasNav={false}
          showProgressBar={false}
          showHelp={false}
        />
      </Col>

      <Col span={24} style={{ marginTop: '16px', padding: '20px 20px 0 20px' }}>
        <div
          style={{
            display: 'inline-block',
            width: '100%',
            position: 'relative',
          }}
        >
          <span style={{ fontSize: '18px', fontWeight: 'bold' }}>
            This address already has a plan
          </span>
          <div
            style={{
              fontWeight: 400,
              marginTop: '8px',
              width: '100%',
              wordWrap: 'break-word',
            }}
          >
            Services and schedules can be managed from within the Digs App
          </div>
        </div>
      </Col>
      <Col
        align="center"
        xs={24}
        md={14}
        lg={12}
        style={{}}
        className="padMobile"
      >
        <AppleGoogleButtons />
      </Col>
    </Row>
  );
};

export default PlanExists;
