import React from 'react';
import { Input } from 'antd';

const PhoneInputMask = ({ value, onChange }) => {
  const handleInputChange = (e) => {
    let value = e.target.value;
    value = value.replace(/[^0-9]/g, '');

    if (value.length > 3 && value.length <= 6)
      value = `${value.slice(0, 3)}-${value.slice(3)}`;
    else if (value.length > 6)
      value = `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`;

    onChange({
      target: {
        value: value,
        name: e.target.name,
      },
    });
  };

  return (
    <Input
      type="text"
      value={value}
      onChange={handleInputChange}
      className="bulky-input"
      style={{ marginTop: '8px' }}
    />
  );
};

export default PhoneInputMask;
