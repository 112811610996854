/**
 * provide different views of a service collection.
 */
export class ServiceFilter {
  /**
   * a view of essential services.
   */
  getEssentialServices(services = [], selected = true) {
    return services.filter(
      (service) =>
        service.location === 'essential' && service.included === selected,
    );
  }
  /**
   * a view of additional services
   */
  getAdditionalServices(services = [], selected = true) {
    return services.filter(
      (service) =>
        service.location === 'additional' && service.included === selected,
    );
  }
  /**
   * a view of consulation services
   */
  getConsultationServices(services = [], selected = true) {
    return services.filter(
      (service) =>
        !service.price && !service.parentId && service.included === selected,
    );
  }
  /**
   * a view of constant services (i.e digs membershipt service)
   */
  getConstantServices(services = []) {
    return services.filter((service) => service.location === 'constant');
  }
  /**
   *  services with a price
   * @param {all} services
   * @returns
   */
  getReadyServices(services = [], selected = true) {
    let ready =  services.filter(
      (service) => service.price && service.included === selected,
    );
    //lets put constants at the end of the list
    const constantServices = ready.filter(x => x.location === 'constant');
    constantServices.forEach(e => {
      ready = this.removeItemAll(ready, e);
    });
    return [...ready, ...constantServices];
  }
   removeItemAll(arr, value) {
    var i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }
  /***
   * a view of selected services excluding constant services.
   */
  getSelectedServices(services = [], includeConstantServices = false) {
    if (includeConstantServices) {
      return services.filter((service) => service.included === true);
    }
    return services.filter(
      (service) => service.included === true && service.location !== 'constant',
    );
  }
  areServicesSelected(services = [], includeConstantServices = false) {
    if (includeConstantServices) {
      return services.filter((service) => service.included === true).length > 0;
    }
    return (
      services.filter(
        (service) =>
          service.included === true &&
          (service.location === 'essential' ||
            service.location === 'additional'),
      ).length > 0
    );
  }
}
