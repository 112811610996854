import { Button, Col, Row, Form, Input, Card } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useInject } from '../common';
import { useNavigate, useParams } from 'react-router-dom';
import { useCustomer } from '../common/customerContext';
import useCustomerNavigation from '../Hooks/NavigationHook';

import { PAGES, CUSTOMER_ACTIONS } from '../constants';
import { CustomerModel } from '../models';

const Cart = () => {
  const {
    locationService,
    propertyInfoService,
    googleService,
    selfSignupCustomerService,
    servicesService,
    logger,
  } = useInject();

  const { subscriptionId } = useParams();
  const customerNavigation = useCustomerNavigation();
  const customer = useCustomer();
  const navigate = useNavigate();

  useEffect(() => {
    async function evaluateSubscription() {
      console.log('subscriptionid', subscriptionId);

      if (subscriptionId?.length != 36) {
        navigate('/');
      }

      const existingCustomer =
        await selfSignupCustomerService.getCustomer(subscriptionId);
      if (existingCustomer?.id) {
        const outcome =
          await selfSignupCustomerService.verifySubscriptionStatus(
            existingCustomer.place_id,
          );
        if (outcome?.existingSubscription === true) {
          customerNavigation(
            PAGES.PLAN_EXISTS,
            CUSTOMER_ACTIONS.PLAN_EXISTS,
            existingCustomer,
          );
        } else {
          existingCustomer.showBuildingPlan = false;
          customerNavigation(
            PAGES.REVIEW,
            CUSTOMER_ACTIONS.CART,
            existingCustomer,
          );
        }
      } else {
        customerNavigation(
          PAGES.CART_NOT_FOUND,
          CUSTOMER_ACTIONS.PLAN_EXISTS,
          existingCustomer,
        );
      }
    }
    evaluateSubscription();
  }, []);
  return (
    <Fragment>
      <Row type="flex" justify="center"></Row>
    </Fragment>
  );
};

export default Cart;
