import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from 'react-router-dom';
import Landing from '../Pages/Landing';
import OutOfArea from '../Pages/OutOfArea';
import HouseSize from '../Pages/HouseSize';
import ServicesConfig from '../Pages/ServicesConfig';
import Review from '../Pages/Review';
import AddressHasPlan from '../Pages/AddressHasPlan';
import Payment from '../Pages/Payment';
import Pricing from '../Pages/Pricing';
import PlanExists from '../Pages/PlanExists';
import SuccessPlan from '../Pages/Success';
import CartNotFound from '../Pages/CartNotFound';
import { PAGES } from '../constants';
import { useSpring, animated } from 'react-spring';
import RouteGuard from '../Components/RouteGuard';
import ScrollToTopOnMount from './ScrollToTop';
import CustomerDetails from '../Pages/CustomerDetails';
import Cart from '../Pages/Cart';

const TransitionWrapper = ({ children }) => {
  // This useLocation is needed to trigger the animation on route change
  // eslint-disable-next-line no-unused-vars
  const location = useLocation();
  const props = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    reset: true,
    config: { tension: 100, friction: 50, mass: 10 },
  });

  return <animated.div style={props}>{children}</animated.div>;
};

const AppRouter = () => {
  return (
    <Router>
      <ScrollToTopOnMount />
      <TransitionWrapper>
        <Routes>
          <Route exact path={PAGES.MEET_PARAMETERIZED} element={<Landing />} />
          <Route exact path={PAGES.MEET_DEFAULT} element={<Landing />} />
          <Route exact path={PAGES.REFERRAL_CODE} element={<Landing />} />
          <Route exact path="/" element={<Landing />} />
          <Route path={`/${PAGES.OUT_OF_AREA}`} element={<OutOfArea />} />
          <Route
            path={`/${PAGES.HOUSE_SIZE}`}
            element={
              <RouteGuard>
                <HouseSize />
              </RouteGuard>
            }
          />
          <Route
            path={`/${PAGES.MEET_CUSTOMER_DETAILS}`}
            element={
              <RouteGuard>
                <CustomerDetails />
              </RouteGuard>
            }
          />
          <Route
            path={`/${PAGES.SERVICES}`}
            element={
              <RouteGuard>
                <ServicesConfig />
              </RouteGuard>
            }
          />
          <Route
            path={`/${PAGES.REVIEW}`}
            element={
              <RouteGuard>
                <Review />
              </RouteGuard>
            }
          />
          <Route
            path={`/${PAGES.PAYMENT}`}
            element={
              <RouteGuard>
                <Payment />
              </RouteGuard>
            }
          />
          <Route path={`/${PAGES.CART}`} element={<Cart />} />
          <Route
            path={`/${PAGES.ADDRESS_HAS_PLAN}`}
            element={<AddressHasPlan />}
          />
          <Route
            path={`/${PAGES.SUCCESS}`}
            element={
              <RouteGuard>
                <SuccessPlan />
              </RouteGuard>
            }
          />
          <Route path={`/${PAGES.CART_NOT_FOUND}`} element={<CartNotFound />} />
          <Route path={`/pricing`} element={<Pricing />} />
          <Route
            path={`/${PAGES.PLAN_EXISTS}`}
            element={
              <RouteGuard>
                <PlanExists />
              </RouteGuard>
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </TransitionWrapper>
    </Router>
  );
};
export default AppRouter;
