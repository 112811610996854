import React, { useContext } from 'react';
import { Drawer, Row, Col, Button } from 'antd';
import { useMobile, useTablet, useDesktop } from '../Helpers/responsive';

const EssentialInfoDrawer = ({ visible, onClose }) => {
  const isMobile = useMobile();
  const isTablet = useTablet();
  const isDesktop = useDesktop();

  const getDrawerPlacement = () => {
    if (!isDesktop) return 'bottom';
    return 'right';
  };

  const getDrawerHeight = () => {
    if (isMobile) return '90%';
    return '75%';
  };

  const essentialTagStyle = {
    container: {
      position: 'absolute',
      border: 'solid 2px #00B47D',
      color: '#00B47D',
      fontSize: '16px',
      padding: '8px 12px',
      borderRadius: '20px',
      textTransform: 'uppercase',
      alignSelf: 'flex-start',
      textAlign: 'center',
      width: '100px',
      top: '45%',
      left: 'calc(50% - 90px)',
      background: 'white',
    },
  };

  const positionWrapper = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '45px',
    position: 'relative',
    height: '120px',
  };

  const questionStyle = {
    position: 'absolute',
    top: '25%',
    left: 'calc(50% - 115px)',
  };

  const questionTiltedStyle = {
    position: 'absolute',
    top: '32.5%',
    left: 'calc(50% - 135px)', // Adjust as needed
  };

  const sparkleStyle = {
    position: 'absolute',
    top: '34.5%',
    right: 'calc(50% - 52.5px)', // Adjust as needed
  };

  return (
    <Drawer
      placement={getDrawerPlacement()}
      className="custom-drawer"
      closable={true}
      onClose={onClose}
      open={visible}
      height={getDrawerHeight()}
      width={isTablet || isDesktop ? '550px' : undefined}
      style={{
        backgroundColor: '#F7F6F2', // Set the background color to orange
        ...(!isDesktop
          ? { borderTopLeftRadius: '15px', borderTopRightRadius: '15px' }
          : {}),
      }}
    >
      <Row gutter={[16, 16]} type="flex" justify={'center'}>
        <Col span={24} style={{ textAlign: 'center' }}>
          <div className="drawer-title">Essential Services?</div>
        </Col>
        <Col span={24} style={{ textAlign: 'center' }}>
          <div style={positionWrapper}>
            <div style={essentialTagStyle.container}>
              <span>Essential</span>
            </div>
            <img src="/images/Question.png" width="20" style={questionStyle} />
            <img
              src="/images/QuestionTilted.png"
              width="25"
              style={questionTiltedStyle}
            />
            <img src="/images/Circle.png" width="160" />
            <img src="/images/Sparkle.png" width="25" style={sparkleStyle} />
          </div>
        </Col>
        <Col span={24} style={{ textAlign: 'center' }}>
          We believe that maintaining your home starts with a handful of core,
          essential services that all home maintenance plans should include.
          It’s best to do these services on a regular basis.
        </Col>

        <Col span={24} style={{ textAlign: 'center', marginTop: '16px' }}>
          <Button className="main-button" type="primary" onClick={onClose}>
            Got it - Close
          </Button>
        </Col>
      </Row>
    </Drawer>
  );
};

export default EssentialInfoDrawer;
