import { Navigate } from 'react-router';
import { useCustomer } from '../common/customerContext';
/** ensures the customer model is set before navigating to a page */
const RouteGuard = ({ children }) => {
  const customer = useCustomer();
  if (customer?.id === undefined) {
    return <Navigate to="/" replace />;
  }

  return children;
};
export default RouteGuard;
