import { createContext, useReducer, useContext } from 'react';
import { CUSTOMER_ACTIONS } from '../constants';
import mergePriceItems from './mergePriceItems';
/**
 * to avoid having to pass the customer data through all components, we can create
 * a simple context to do the work for use.  It also provides an update method in to handle user selections.
 */

const customerState = sessionStorage.getItem(
  CUSTOMER_ACTIONS.LOCAL_SESSION_KEY,
);
let initalState = {};
if (customerState?.length > 1) {
  initalState = JSON.parse(customerState);
  if (initalState) {
    if (initalState.services) {
      initalState.services = mergePriceItems(initalState.services);
    }
  }
}
export const CustomerContext = new createContext(initalState);
export const CustomerDispatchContext = new createContext();
// Define a component that provides the container to its children
export const CustomerProvider = ({ children }) => {
  const [customer, dispatch] = useReducer(customerReducer, initalState);
  return (
    <CustomerContext.Provider value={customer}>
      <CustomerDispatchContext.Provider value={dispatch}>
        {children}
      </CustomerDispatchContext.Provider>
    </CustomerContext.Provider>
  );
};

export function useCustomer() {
  return useContext(CustomerContext);
}

export function useCustomerDispatch() {
  const dispatch = useContext(CustomerDispatchContext);
  return (action, model) => {
    dispatch({ type: action, value: model });
  };
}

const customerReducer = (state, action) => {
  const internalReducer = (state, action) => {
    switch (action.type) {
      case CUSTOMER_ACTIONS.SET_TSHIRT_SIZE: {
        const propertySizeChangedCount =
          (state.propertySizeChangedCount || 0) + 1;

        state.tshirtSize = action.value;

        return { ...state, propertySizeChangedCount: propertySizeChangedCount };
      }
      case CUSTOMER_ACTIONS.HOUSE_SIZE: {
        return action.value;
      }
      case CUSTOMER_ACTIONS.HOME: {
        return action.value;
      }
      case CUSTOMER_ACTIONS.OUT_OF_AREA: {
        return action.value;
      }
      case CUSTOMER_ACTIONS.SERVICES: {
        //set selected and reg services
        const exactPropertySize = state.propertySize;
        const tshirt = state.tshirtSize;

        action.value = action.value.map((s) => {
          s.setTShitSize(
            tshirt,
            exactPropertySize,
            state.propertySizeChangedCount,
          );
          return s;
        });

        state.services = action.value;

        return state;
      }
      case CUSTOMER_ACTIONS.UPDATE_SERVICES: {
        state.services = action.value;
        return state;
      }
      case CUSTOMER_ACTIONS.UPDATE_SELECTED_SERVICES: {
        return state;
      }
      case CUSTOMER_ACTIONS.PAYMENT: {
        state.status = CUSTOMER_ACTIONS.PAYMENT;
        return { ...state, ...action.value };
      }
      case CUSTOMER_ACTIONS.HELP: {
        state.status = CUSTOMER_ACTIONS.HELP;
        return { ...state, ...action.value };
      }
      case CUSTOMER_ACTIONS.LEADGEN: {
        state.status = CUSTOMER_ACTIONS.LEADGEN;
        return { ...state, ...action.value };
      }
      case CUSTOMER_ACTIONS.COMPLETE: {
        state.status = CUSTOMER_ACTIONS.COMPLETE;
        sessionStorage.clear();
        return { ...state, ...action.value };
      }
      case CUSTOMER_ACTIONS.PLAN_EXISTS: {
        return action.value;
      }
      case CUSTOMER_ACTIONS.MEET_CUSTOMER_DETAILS: {
        state.status = CUSTOMER_ACTIONS.MEET_CUSTOMER_DETAILS;
        return { ...state, ...action.value };
      }
      case CUSTOMER_ACTIONS.CART: {
        state.status = CUSTOMER_ACTIONS.CART;
        return { ...state, ...action.value };
      }
      default:
        return { ...state };
    }
  };
  const result = internalReducer(state, action);
  sessionStorage.setItem(
    CUSTOMER_ACTIONS.LOCAL_SESSION_KEY,
    JSON.stringify(result),
  );
  return result;
};
