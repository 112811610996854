import React, { Fragment, useState } from 'react';
import { Button, Input } from 'antd';

const Counter = ({ value, onChange, validation = true, ...rest }) => {
  const [count, setCount] = useState(value || 0);
  const increase = () => {
    const val = count + 1;
    setCount(val);
    onChange(val);
  };

  const decrease = () => {
    if (count > 0) {
      const val = count - 1;
      setCount(val);
      onChange(val);
    }
  };
  const buttonStyle = {
    width: '40px',
    height: '40px',
    border: 'none',
    fontSize: '20px',
  };

  return (
    <Fragment>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          border: '1px solid #d9d9d9',
          borderRadius: '4px',
          width: '100%',
        }}
      >
        <Button
          style={{ ...buttonStyle, borderRight: '1px solid #d9d9d9' }}
          onClick={decrease}
          disabled={count === 0}
        >
          -
        </Button>
        <Input
          type="number"
          value={count}
          required
          readOnly
          style={{
            flex: 1,
            fontSize: '18px',
            textAlign: 'center',
            border: 'none',
            color: count > 0 ? '#00b47d' : 'black',
          }}
        />
        <Button
          style={{ ...buttonStyle, borderLeft: '1px solid #d9d9d9' }}
          onClick={increase}
        >
          +
        </Button>
      </div>
      {validation && rest['aria-invalid'] && (
        <div style={{ color: 'red' }}>Please select at least 1</div>
      )}
    </Fragment>
  );
};

export default Counter;
