import { ServiceFilter } from '../common';
import sumPlan from '../common/sumPlan';
import { isIOS, isAndroid } from 'react-device-detect';
import { generateDisplayText } from '../Helpers/generateDisplayText';

export class CommunicationService {
  constructor(logger, httpService) {
    this.logger = logger;
    this.httpService = httpService;
  }

  sendEmail = async (emailModel) => {
    try {
      const result = await this.httpService.post(
        `${process.env.REACT_APP_COMMUNICATION_API}/send-email`,
        emailModel,
      );
      return result.data;
    } catch (error) {
      this.logger?.log(error);
      return undefined;
    }
  };

  sendWelcomeEmail = async (customer) => {
    try {
      const serviceFilter = new ServiceFilter();
      const constantServices = serviceFilter.getConstantServices(
        customer.services,
      );
      const essentialServices = serviceFilter.getEssentialServices(
        customer.services,
        true,
      );
      const additionalServices = serviceFilter.getAdditionalServices(
        customer.services,
        true,
      );
      const consultationServices = serviceFilter.getConsultationServices(
        customer.services,
        true,
      );
      const selectedServices = serviceFilter.getSelectedServices(
        customer.services,
        true,
      );

      const emailData = {
        plan: {
          pricepermonth: (sumPlan(selectedServices) / 12).toFixed(2),
          priceperyear: sumPlan(selectedServices).toFixed(2),
          customerFirstName: customer.firstName,
          customerLastName: customer.lastName,
          email: customer.emailAddress,
          market: 'Atlanta',
          address1: customer?.address?.street,
          city: customer?.address?.city,
          state: customer?.address?.state,
          zip: customer?.address?.zip,
        },
        essential_services: [],
        other_services: [],
        consultation_services: [],
        membership: [],
      };

      constantServices.map((s) => {
        let service = {
          service_name: s.name,
          priceperyear: s.price.toFixed(2),
          pricepermonth: (s.price / 12).toFixed(2),
          unit: '1',
          visitperyear: s.perYear,
        };

        emailData.membership.push(service);
      });

      essentialServices.map((service) => {
        const childServices = essentialServices.filter(
          (s) => s.parentId === service.serviceId,
        );

        let serviceItem = {
          service_name: service.name,
          priceperyear: service.price.toFixed(2),
          pricepermonth: (service.price / 12).toFixed(2),
          unit: generateDisplayText(service, childServices),
          visitperyear: service.perYear,
        };

        emailData.essential_services.push(serviceItem);
      });

      additionalServices.map((service) => {
        const childServices = additionalServices.filter(
          (s) => s.parentId === service.serviceId,
        );

        let serviceItem = {
          service_name: service.name,
          priceperyear: service.price.toFixed(2),
          pricepermonth: (service.price / 12).toFixed(2),
          unit: generateDisplayText(service, childServices),
          visitperyear: service.perYear,
        };

        emailData.other_services.push(serviceItem);
      });

      consultationServices.map((service) => {
        let serviceItem = {
          service_name: service.name,
          priceperyear: 'TBD',
          pricepermonth: 'TBD',
          unit: 'Price set after consultation',
          visitperyear: 'Consultation to be scheduled within 2 weeks',
        };

        emailData.consultation_services.push(serviceItem);
      });

      const result = await this.httpService.post(
        `${process.env.REACT_APP_COMMUNICATION_API}/send-welcome-email`,
        emailData,
      );
      return result.data;
    } catch (error) {
      this.logger?.log(error);
      return undefined;
    }
  };

  sendSmsMessage = async (customer) => {
    try {
      const downloadUrl = 'https://www.digs.company/app';

      const smsMessage = {
        phoneNumber: customer.phoneNumber.replace(/-/g, ''),
        message: encodeURI(
          'Thanks for signing up for a Digs Annual Maintenance Plan! Next step is to download the app here:\n\n' +
            downloadUrl +
            '\n\nP.s.  Save this number to your contacts.  You can call or text us here. ',
        ),
        firstName: customer.firstName,
        lastName: customer.lastName,
        email: customer.emailAddress,
        contactType: 'customer',
      };
      const result = await this.httpService.post(
        `${process.env.REACT_APP_COMMUNICATION_API}/sendsalesmsg`,
        smsMessage,
      );
      return result.data;
    } catch (error) {
      this.logger?.log(error);
      return undefined;
    }
  };
}
