import { PriceItem } from '../common/PriceItem';

export class ServicesService {
  constructor(logger, httpService) {
    this.logger = logger;
    this.httpService = httpService;
  }

  getServices = async (marketId, customer) => {
    try {
      const parameters = { marketId: marketId, selfService: true };
      const result = await this.httpService.get(
        `${process.env.REACT_APP_YEARLY_SUBSCRIPTIONS}/services`,
        parameters,
      );
      return result.data?.map((service) => {
        service.tshirtSize = customer?.tshirtSize;
        return new PriceItem(service);
      });
    } catch (error) {
      this.logger?.log(error);
      return undefined;
    }
  };

  isValidReferralCode = async (referralCode) => {
    try {
      const result = await this.httpService.get(
        `${process.env.REACT_APP_YEARLY_SUBSCRIPTIONS}/verify-referral-code/${referralCode}`,
      );
      return result.data;
    } catch (error) {
      this.logger?.log(error);
      return undefined;
    }
  };
  validateMeetCode = async (meetCode) => {
    if (!meetCode?.length > 0) {
      return undefined;
    }
    if (meetCode?.length > 0 && meetCode === 'unassigned') {
      return meetCode;
    }
    try {
      const result = await this.httpService.get(
        `${process.env.REACT_APP_YEARLY_SUBSCRIPTIONS}/validate-meet-code?code=${meetCode}`,
      );
      return result.data;
    } catch (error) {
      this.logger?.log(error);
      return undefined;
    }
  };
}
