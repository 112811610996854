import {
  LocationService,
  PropertyInfoService,
  ServicesService,
  SelfSignupCustomerService,
  GoogleService,
  CommunicationService,
} from '../API';
import { ConsoleLogger } from './ConsoleLogger';
import { HttpService } from '../services/HttpService';

//our console.log wrapper.  In production we may want to use something like LogRocket. Or simply disable logging.
const logger = new ConsoleLogger('console-logger');
//all things http.
const httpService = new HttpService(logger);
//the location service with a reference to our shared logger.
const locationService = new LocationService(logger, httpService);
//the propertyInfo service with a reference to our shared logger.
const propertyInfoService = new PropertyInfoService(logger, httpService);
//the services service with a referenfe to our shared logger.
const servicesService = new ServicesService(logger, httpService);
///
const selfSignupCustomerService = new SelfSignupCustomerService(
  logger,
  httpService,
);

const googleService = new GoogleService(logger);
const communicationService = new CommunicationService(logger, httpService);

export const registry = {
  logger,
  locationService,
  propertyInfoService,
  servicesService,
  selfSignupCustomerService,
  httpService,
  googleService,
  communicationService,
};
