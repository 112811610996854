export class GoogleService {
  constructor(logger) {
    this.logger = logger;
  }
  getStreetViewImageUrl(location) {
    const latitude = location.lat();
    const longitude = location.lng();
    const formatLocation = `${latitude},${longitude}`;
    const streetViewUrl = `${process.env.REACT_APP_GOOGLE_STREETVIEW_ENDPOINT}?size=1920x1080&location=${formatLocation}&key=${process.env.REACT_APP_GOOGLE_MAPS_API}&return_error_code=true`;

    try {
      return streetViewUrl;
    } catch (error) {
      throw new Error('Could not fetch Street View image.');
    }
  }
}
