import ProgressHeader from '../Components/Styled/ProgressHeader';
import { Col, Row } from 'antd';
import AppleGoogleButtons from '../Components/Styled/AppleGoogleButtons';
import { useEffect } from 'react';

const SuccessPlan = () => {
  useEffect(() => {
    window.sessionStorage.clear();
  }, []);
  return (
    <Row type="flex" justify="center">
      <ProgressHeader
        title={'Welcome to Digs!'}
        hasNav={true}
        showBackIcon={false}
      ></ProgressHeader>
      <Col xs={0} md={5} lg={6}></Col>
      <Col
        align="center"
        xs={24}
        md={14}
        lg={12}
        style={{ marginTop: '16px' }}
        className="padMobile"
      >
        Thank you for signing up for a Digs Annual Maintenance Plan for your
        home. What now? Install the Digs App to view your maintenance calendar
        and see your first services. If you have any questions, we’re here to
        help.
      </Col>
      <Col xs={0} md={5} lg={6}></Col>

      <Col xs={0} md={5} lg={6}></Col>
      <Col
        align="center"
        xs={24}
        md={14}
        lg={12}
        style={{ marginTop: '16px' }}
        className="padMobile"
      >
        <img
          src="/images/phone-image-success.png"
          alt="digs company mobile app"
        />
      </Col>
      <Col xs={0} md={5} lg={6}></Col>

      <Col xs={0} md={5} lg={6}></Col>
      <Col
        align="center"
        xs={24}
        md={14}
        lg={12}
        style={{}}
        className="padMobile"
      >
        <AppleGoogleButtons />
      </Col>
      <Col xs={0} md={5} lg={6}></Col>
    </Row>
  );
};

export default SuccessPlan;
