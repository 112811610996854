import currencyFormatter from 'currency-formatter';

export function formatMoney(value, decimalDigits, format) {
  let defaultFormat = currencyFormatter.findCurrency('USD');
  if (!decimalDigits && decimalDigits !== 0) {
    decimalDigits = 2;
  }
  let roundedValue = Number(Number(value).toFixed(decimalDigits));
  return currencyFormatter.format(roundedValue, {
    ...defaultFormat,
    precision: decimalDigits,
    format: format
      ? format
      : {
          pos: '%s%v', // %s is the symbol and %v is the value
          neg: '(%s%v)',
          zero: '%s%v',
        },
  });
}
