export class PropertyInfoService {
  constructor(logger, httpService) {
    this.logger = logger;
    this.httpService = httpService;
  }
  getPropertyDetailsByAddress = async (customer) => {
    try {
      this.logger?.log('selected address', customer.address);
      ///source=masvh
      const params = {
        address: customer.address.street,
        city: customer.address.city,
        state: customer.address.state,
        zip: customer.address.zip,
        formatted: customer.formattedAddress,
        source: 'pubrec',
      };
      const result = await this.httpService.get(
        `${process.env.REACT_APP_YEARLY_SUBSCRIPTIONS}/properties/searchByAddress`,
        params,
      );
      this.logger?.log('getPropertyDetailsByAddress', result.data);
      return result.data;
    } catch (error) {
      this.logger?.log(error);
      return undefined;
    }
  };
  getHouseSizes = async (propertySize) => {
    try {
      const result = await this.httpService.get(
        `${
          process.env.REACT_APP_YEARLY_SUBSCRIPTIONS
        }/properties/getHouseSizes?propertySize=${propertySize ?? 0}`,
      );
      return result.data;
    } catch (error) {
      this.logger?.log(error);
      return undefined;
    }
  };
}
