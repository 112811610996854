import React, { Fragment } from 'react';
import RadioButtons from './RadioButtons';
import { Form, Radio } from 'antd';
import Counter from '../Styled/Counter';

const ConfigureService = ({ service, form, onFormComplete, isChild }) => {
  const buildSelectorOptions = (selectors) => {
    const s = selectors.map((s) => {
      return { value: s.unit, label: s.unit, info: s.info };
    });

    return s;
  };

  const initialValues = isChild
    ? {
        [`child-${service.serviceId}`]: {
          quantity: service?.selectedUnit?.quantity,
          unit: service?.selectedUnit?.unit,
        },
      }
    : {
        quantity: service?.selectedUnit?.quantity || 1,
        unit: service?.selectedUnit?.unit,
      };

  const getName = (name) => {
    if (isChild) {
      return [`child-${service.serviceId}`, name];
    }

    return name;
  };
  const greaterThanZero = (rule, value) => {
    if (value > 0 || isChild) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Please select at least 1'));
  };

  return (
    <Fragment>
      <Form form={form} initialValues={initialValues} onFinish={onFormComplete}>
        {service.options.type === 'number' &&
          service.options.display !== 'none' && (
            <Form.Item
              name={getName('quantity')}
              noStyle
              trigger="onChange"
              rules={[
                {
                  validator: greaterThanZero,
                },
              ]}
            >
              <Counter validation={!isChild} />
            </Form.Item>
          )}

        {service.options.type === 'select' && (
          <Form.Item
            name={getName('unit')}
            noStyle
            rules={[{ required: true }]}
          >
            <Radio.Group style={{ display: 'block' }}>
              <RadioButtons
                values={buildSelectorOptions(service.options.selectors)}
              />
            </Radio.Group>
          </Form.Item>
        )}
      </Form>
    </Fragment>
  );
};

export default ConfigureService;
