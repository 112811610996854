export class CustomerModel {
  constructor(
    marketId,
    placeId,
    google_street_view,
    selectedAddress,
    propertySize = undefined,
    tshirtSize = undefined,
    houseSizes = undefined,
    referralCode = undefined,
    services = undefined,
    emailAddress = undefined,
    firstName = undefined,
    lastName = undefined,
    phoneNumber = undefined,
    status = '',
    created = undefined,
    updated = undefined,
    meetCode = undefined,
    cartId = undefined,
    formFilled,
  ) {
    this.id = undefined;
    this.marketId = marketId;
    this.placeId = placeId;
    this.google_street_view = google_street_view;
    this.propertySize = propertySize;
    this.tshirtSize = tshirtSize;
    this.formattedAddress = selectedAddress?.formatted_address;
    this.address = this.extractAddressInfo(selectedAddress?.address_components);
    this.googleAddress = selectedAddress;
    this.services = services;
    this.houseSizes = houseSizes;
    this.emailAddress = emailAddress;
    this.firstName = firstName;
    this.lastName = lastName;
    this.phoneNumber = phoneNumber;
    this.status = status;
    this.referralCode = referralCode;
    this.created = created;
    this.updated = updated;
    this.deleted = false;
    this.publicData = {};
    this.welcomeBack = false;
    this.meetCode = meetCode;
    this.cartId = cartId;
    this.formFilled = formFilled;
  }
  extractAddressInfo = (arr) => {
    if (arr === undefined) {
      return {};
    }
    const address = {
      street: '',
      city: '',
      state: '',
      zip: '',
    };
    for (let item of arr) {
      if (item.types.includes('street_number')) {
        address.street += item.long_name + ' ';
      } else if (item.types.includes('route')) {
        address.street += item.long_name;
      } else if (item.types.includes('locality')) {
        address.city = item.long_name;
      } else if (item.types.includes('administrative_area_level_1')) {
        address.state = item.short_name;
      } else if (item.types.includes('postal_code')) {
        address.zip = item.long_name;
      }
    }
    return address;
  };
}
