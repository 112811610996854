import { useLocation, useParams, useResolvedPath } from 'react-router-dom';

const useSalesMeet = () => {
  const regex = /\/\bmeet\b/gm;
  const { meetCode } = useParams();
  const { pathname } = useLocation();

  if (!meetCode & (regex.exec(pathname) != null)) {
    return { meetCode: 'unassigned' };
  } else {
    return { meetCode };
  }
};

export default useSalesMeet;
