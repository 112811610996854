import { App, Col, Row, Form, Input, Button, Drawer, AutoComplete } from 'antd';
import { useState } from 'react';
import { useCustomer, useCustomerDispatch } from '../common/customerContext';
import { CUSTOMER_ACTIONS } from '../constants';
import PhoneInputMask from '../Helpers/PhoneInputMask';

const CustomerForm = ({ action }) => {
  const customer = useCustomer();
  const dispatch = useCustomerDispatch();

  const setFirstName = (value) => {
    dispatch(action, {
      firstName: value,
    });
  };
  const setLastName = (value) => {
    dispatch(action, {
      lastName: value,
    });
  };
  const setPhoneNumber = (value) => {
    dispatch(action, {
      phoneNumber: value,
    });
  };
  const setEmailAddress = (value) => {
    dispatch(action, {
      emailAddress: value,
    });
  };

  const validateName = (rule, value) => {
    // Regex pattern to allow only letters, hyphens, and apostrophes
    const pattern = /^[^<>]+$/;
    if (pattern.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject('Invalid character(s) used.');
  };

  return (
    <div>
      <Form.Item
        className={'custom-form-item'}
        name="firstName"
        label={'First Name'}
        rules={[{ required: true, message: 'Please input your first name' }]}
        validateTrigger="onBlur"
      >
        <Input
          className="bulky-input"
          style={{ marginTop: '8px' }}
          onChange={(e) => setFirstName(e.target.value)}
          name="first-name"
          autoComplete="first-name"
          type="first-name"
          id="first-name"
        />
      </Form.Item>

      <Form.Item
        className={'custom-form-item'}
        name="lastName"
        label={'Last Name'}
        rules={[{ required: true, message: 'Please input your last name' }]}
        validateTrigger="onBlur"
      >
        <Input
          className="bulky-input"
          style={{ marginTop: '8px' }}
          onChange={(e) => setLastName(e.target.value)}
          name="family-name"
          autoComplete="family-name"
          type="family-name"
          id="family-name"
        />
      </Form.Item>

      <Form.Item
        className={'custom-form-item'}
        name="phoneNumber"
        label={'Phone Number'}
        rules={[
          { required: true, message: 'Please input your phone number' },
          {
            pattern: /^\d{3}-\d{3}-\d{4}$/,
            message: 'Please enter a valid phone number',
          },
        ]}
        validateTrigger="onBlur"
      >
        <PhoneInputMask onChange={(e) => setPhoneNumber(e.target.value)}>
          {(inputProps) => (
            <Input
              {...inputProps}
              className="bulky-input"
              style={{ marginTop: '8px' }}
              name="tel"
              autoComplete="tel"
              type="tel"
            />
          )}
        </PhoneInputMask>
      </Form.Item>

      <Form.Item
        className={'custom-form-item'}
        name="emailAddress"
        label={'Email'}
        rules={[
          { required: true, message: 'Please input your email' },
          {
            pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            message: 'Please enter a valid email address',
          },
        ]}
        validateTrigger="onBlur"
      >
        <Input
          className="bulky-input"
          style={{ marginTop: '8px' }}
          onChange={(e) => setEmailAddress(e.target.value)}
          name="email"
          autoComplete="email"
          type="email"
          id="email"
        />
      </Form.Item>
    </div>
  );
};

export default CustomerForm;
