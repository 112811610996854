export function generateDisplayText(service, childServices) {
  if (service.options?.price === null) {
    return 'Consultation Required';
  }
  let text = formatServiceText(service);
  if (childServices) {
    const childText = childServices
      .map((childService) => {
        return formatServiceText(childService);
      })
      .filter((t) => t);
    if (childText.length > 0) {
      text += `, ${childText.join(', ')}`;
    }
  }
  return text;
}
const pluralize = (count, singular, plural) => {
  return count === 1 ? singular : plural;
};

const formatServiceText = (service) => {
  let text = '';
  if (service.included) {
    if (service.options?.areas?.some((area) => area.room)) {
      const roomCount = service.selectedUnits?.length || 0;
      text = `${roomCount} ${pluralize(roomCount, 'Room', 'Rooms')}`;
    } else if (service.options?.areas) {
      const areaCount = service.selectedUnits.reduce(
        (sum, unit) => sum + (unit.quantity || 0),
        0,
      );
      text = `${areaCount} ${pluralize(areaCount, 'Area', 'Areas')}`;
    } else if (
      service.name !== 'House Wash' &&
      service.options?.type === 'none'
    ) {
      text = 'Customized';
    } else if (service.selectedUnit?.info) {
      if (
        service.selectedUnit.info.startsWith('#') &&
        !service.selectedUnit?.quantity
      ) {
        return text;
      }

      text = service.selectedUnit.info.startsWith('#')
        ? service.selectedUnit.info.replace(
            '#',
            service.selectedUnit?.quantity || '',
          ) + (service.selectedUnit.quantity > 1 ? 's' : '')
        : service.selectedUnit.info;
    } else {
      text = service.selectedUnit?.unit || service.selectedUnit?.quantity || '';
    }
  }
  return text;
};
