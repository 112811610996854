import React from 'react';
import SelectServices from '../Components/ServicesConfig/SelectServices';
import { useCustomer, useCustomerDispatch } from '../common/customerContext';
import { CUSTOMER_ACTIONS, PAGES } from '../constants';
import useCustomerNavigation from '../Hooks/NavigationHook';
import { useInject } from '../common';

const ServicesConfig = ({ back }) => {
  const customer = useCustomer();
  const customerNavigation = useCustomerNavigation();
  const dispatch = useCustomerDispatch();
  const { selfSignupCustomerService } = useInject();

  const updateServiceSelections = () => {
    customer.showBuildingPlan = true;
    customer.status = PAGES.REVIEW;
    customerNavigation(PAGES.REVIEW, CUSTOMER_ACTIONS.REVIEW, customer);
    selfSignupCustomerService.saveCustomerProgress(customer);
  };

  const onServiceConfigUpdated = (service) => {
    const services = customer.services.map((s) => {
      if (s.serviceId === service.serviceId) {
        return service;
      }
      return s;
    });
    dispatch(CUSTOMER_ACTIONS.UPDATE_SERVICES, services);
  };

  return (
    <SelectServices
      onComplete={updateServiceSelections}
      onServiceUpdate={onServiceConfigUpdated}
      back={back}
    />
  );
};

export default ServicesConfig;
