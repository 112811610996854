import { Col, InputNumber, Row, Form } from 'antd';
import React from 'react';
import MultiSelect from './MultiSelect';
import multiSelectDataMapper from './MultiSelect/multiSelectDataMapper';
import MultiAreaInput from './MultiAreaInput';
import multiAreaInputDataMapper from './MultiAreaInput/multiAreaInputDataMapper';

const SERVICE_TYPES = {
  MULTISELECT: 'multiSelect',
  MULTIPLE_AREA_INPUT: 'multiple-area-input',
};

export default ({ service, form, onComplete }) => {
  const { options } = service;
  const type = options.type;

  const isType = (check) => {
    return check == type;
  };
  const getFormInput = (service) => {
    if (isType(SERVICE_TYPES.MULTISELECT)) {
      return <MultiSelect service={service} form={form} />;
    }

    if (isType(SERVICE_TYPES.MULTIPLE_AREA_INPUT)) {
      return <MultiAreaInput service={service} form={form} />;
    }
  };

  const handleSubmit = (data) => {
    const resultService = {
      ...service,
    };
    if (isType(SERVICE_TYPES.MULTISELECT)) {
      const newService = multiSelectDataMapper(resultService, data);
      onComplete(newService);
    }
    if (isType(SERVICE_TYPES.MULTIPLE_AREA_INPUT)) {
      const newService = multiAreaInputDataMapper(resultService, data);
      onComplete(newService);
    }
  };
  return (
    <Form
      layout={'vertical'}
      form={form}
      initialValues={{
        ...service,
        selectedUnits: service.selectedUnits?.length
          ? service.selectedUnits
          : [{}],
      }}
      onFinish={handleSubmit}
    >
      {/* <Row type="flex">
        <Col xs={24} style={{ fontWeight: "bold" }}>
          {service.name}
        </Col>
      </Row> */}

      {getFormInput(service)}

      {/* <Form.Item
        label="Per Year"
        name={"perYear"}
        style={{ marginTop: "8px" }}
        rules={[
          {
            required: true,
            message: "Please input per year",
          },
        ]}
      >
        <InputNumber
          placeholder="Enter Per Year"
          type="number"
        />
      </Form.Item> */}
    </Form>
  );
};
