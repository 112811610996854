import React from 'react';
import { formatMoney } from '../../Helpers/formatters';

const PriceDisplay = ({ price }) => {
  const formattedPrice = formatMoney(price).replace('$', '');
  const [integerPart, decimalPart] = formattedPrice.split('.');

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#00b47d',
        color: 'white',
        padding: '16px',
        borderRadius: '4px',
      }}
    >
      <div>Your Custom Annual Maintenance Plan</div>

      <div
        style={{
          display: 'inline-flex',
          alignItems: 'flex-start',
          fontSize: '42px',
          position: 'relative',
          marginTop: '8px',
        }}
      >
        <span
          style={{
            fontSize: '20px',
            position: 'relative',
            top: '4px',
            right: '4px',
          }}
        >
          $
        </span>
        {integerPart}
        <span
          style={{
            fontSize: '14px',
            position: 'relative',
            top: '8px',
            right: '-4px',
          }}
        >
          .{decimalPart}
        </span>
        <span
          style={{
            fontSize: '14px',
            position: 'absolute',
            bottom: '10px',
            right: '-8px',
          }}
        >
          /mo
        </span>
      </div>
    </div>
  );
};

export default PriceDisplay;
