import React, { Fragment, useState, useContext, useEffect } from "react";
import { Button, Col, Row, Form, Input, Card, Checkbox, Popover } from "antd";
import { InfoCircleOutlined, EditOutlined, VerticalAlignMiddleOutlined } from '@ant-design/icons'
import { PriceItem } from "../common/PriceItem";
import { useInject } from "../common";
import { firstBy } from "thenby";
import Enumerable from "linq";
import "./Pricing.css";
import ServiceDrawer from "../Components/ServiceDrawer";

const tshirtSizes = {
    small: 'small',
    medium: 'medium',
    large: 'large',
    xlarge: 'xLarge',
}





var tshirtSizeKeys = Object.keys(tshirtSizes);




const Pricing = () => {
    const { servicesService } = useInject();
    const [pricingData, setPricingData] = useState({
        services: [],
        services: [],
        data: []
    });
    const [isDrawerVisible, setDrawerVisible] = useState(false);
    const [selectedService, setSelectedService] = useState({});
    const [marketId, setMarketId] = useState('c4fe746b-2239-4cee-b8a4-270e7a079518');
    const toggleDrawer = () => {
        setDrawerVisible(!isDrawerVisible)
    }


    useEffect(() => {
        async function fetch() {
            //retreive all services for a given market.  As more markets emerge, we could add a drop down list to select markets.
            const allServices = await servicesService.getServices(marketId);

            //for every t-shirt size, we want to create a size specific service so we can compute the cost with default options
            //future enahncement could allow a user to modify options.
            const servicesBySize = tshirtSizeKeys.map(size => {

                const priceItems = allServices.map(z => {
                    //clone each service/price item
                    let cl = PriceItem.mergeSavedService(z)
                    //default on size
                    setAppropriateDefaultSelection(cl, size);
                    //initially select it.
                    cl.selected = true;
                    return cl;

                });
                //project into a new model relating size and services.
                return {
                    size: size, services: priceItems
                }

            });
            //since the services are repeated by size, we need to extract a single set of services to use as our y axis.
            const servicesYAxis = servicesBySize[0].services.map(x => {
                const item = PriceItem.mergeSavedService(x)
                item.selected = true;
                return item;
            });

            //update state
            setPricingData({
                yaxis: servicesYAxis,
                xaxis: servicesBySize.map(x => { return { size: x.size } }),
                data: servicesBySize
            });
        }
        //immediately invoke our internal async service
        fetch();
    }, []);

    const getPopoverContent = (s) => {
        const cost = s?.price / 12 || 0;
        return (<div>

            <div>

                <label>Monthly</label> <span>${cost.toLocaleString()}</span>
            </div>
            <div>
                <label>Yearly</label> <span>${s?.price?.toLocaleString()}</span>
            </div>
            <div>
                <label>Visits/Year</label> <span>{s?.perYear}</span>
            </div>


        </div>);
    }

    const computedPriceCell = (service, size) => {
        const sid = service.serviceId;
        const s = size.size;
        const sizeElement = pricingData?.data?.find(x => x.size === s);
        const srv = sizeElement?.services?.find(x => x.serviceId === sid);

        const cost = srv?.price / 12 || 0;
        return ( service.location != 'constant' ?
            <td className={`${s} price`}>
                <Popover content={getPopoverContent(srv)} title="Price Information">
                    <span >
                        ${srv?.selected === true ? cost.toLocaleString() : '0'}
                    </span>
                </Popover>
            </td> : <td className={`${s} price`}>
            <span >
                        ${srv?.selected === true ? cost.toLocaleString() : '0'}
                    </span>
            </td>
        )


    }
    const setAppropriateDefaultSelection = (priceItem, size) => {
        //setting the tshirt size will set the default options if any for the service.
        priceItem.setTShitSize(size);

        console.log(priceItem?.selectedUnit);
        if (priceItem?.options?.type === 'number') {

        }
        else if (priceItem?.options?.type === 'multiSelect') {

            const area = priceItem.options.areas[0];

            /*priceItem.selectedUnits = [
                {
                    price: area.price,
                    quantity: 1,
                    room: area.room,
                    unit: area.selectors[0].unit
                }
            ]
            
            console.log("Set selected units", priceItem.price);*/
        }
        else if (priceItem?.options?.type === 'multiple-area-input') { }

    }

    const computedPriceTotalCell = (s) => {


        console.log("computedPriceTotalCell");
        const sizeElement = pricingData?.data?.find(x => x.size === s);
        const total = Enumerable.from(sizeElement?.services.filter(x => x.selected === true)).sum(x => x.price) / 12;
        return (
            <td className={s}>${total?.toLocaleString() ?? "0"}</td>
        )
    }



    const updateTotals = (service) => {

        const sid = service.serviceId;

        pricingData.data.forEach(x => {
            const m = x.services.find(x => x.serviceId === sid);
            m.selected = service.selected;
        });

        setPricingData({ ...pricingData });
    }
    const getLabelText = (service) => {
        const l = `${service.name} - ${service.perYear} Per Year`;
        return l;
    }
    const createLabel = (service) => {
        const label = getLabelText(service);
        return <td className={`${service.location} price service`} ><span>{label}</span></td>
    }
    const createCheckBox = (x) => {
        return (<td className="price service">
            <Checkbox checked={x.selected} onChange={(e) => {

                x.selected = e.target.checked;
                updateTotals(x);

            }}> {getLabelText(x)}</Checkbox>
       



        </td>);
    }


    const createServiceCell = (x) => {

        if (x.location === 'constant') {
            return createLabel(x);
        }
        else {
            return createCheckBox(x);
        }

    }
    const toggleSelections = () => {
        pricingData.yaxis.forEach(x => x.selected = !x.selected);
        pricingData.data.forEach(x => x.services.filter(t=>t.location !='constant').forEach(z=>z.selected = !z.selected));
        setPricingData({...pricingData});
    }

    return (


        <Fragment>
            <Row type="flex" justify="center">


                <Row gutter={[16, 16]}>

                    <Col span={24} style={{ textAlign: "center" }}>
                        <img src="/images/digs_logo_color.png" width="80" />
                    </Col>



                    <Col
                        span={22} offset={1}>

                        <table>
                            <thead>
                                <tr>
                                    <th className="service"> <VerticalAlignMiddleOutlined onClick={toggleSelections} /></th>

                                    {pricingData?.xaxis?.map(x => <th className={x.size}>{x.size}</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {



                                    pricingData?.yaxis?.map(x => {
                                        return (

                                            <tr>

                                                {createServiceCell(x)}

                                                {pricingData?.xaxis?.map(s => computedPriceCell(x, s))}
                                            </tr>

                                        )

                                    })

                                }


                            </tbody>
                            <tfoot>
                                <tr>
                                    <td className="service"></td>
                                    {pricingData?.xaxis?.map(s => computedPriceTotalCell(s.size))}
                                </tr>
                            </tfoot>
                        </table>

                    </Col>

                </Row>
            </Row>
            <ServiceDrawer
                visible={isDrawerVisible}
                onClose={toggleDrawer}
                service={selectedService}
                children={pricingData?.services?.filter(s => s.parentId && s.parentId == selectedService?.serviceId)}
                onComplete={(service) => {

                    toggleDrawer();
                }}
            />
        </Fragment>


    );
}
export default Pricing