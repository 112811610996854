import React from 'react';
import { Radio } from 'antd';

const RadioButtons = ({ values }) => {
  return values.map((s, i) => {
    return (
      <label className="input-box-radio" key={i}>
        <Radio value={s.value}>
          <span style={{ fontWeight: 600 }}>{s.label}</span>
          {s.info && (
            <span
              style={{
                marginLeft: '4px',
                fontSize: '12px',
                opacity: '.6',
              }}
            >
              {s.info}
            </span>
          )}
        </Radio>
      </label>
    );
  });
};

export default RadioButtons;
