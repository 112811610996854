import React from 'react';
import { Col } from 'antd';
import {
  ArrowLeftOutlined,
  HighlightFilled,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import ServiceAddress from '../Address/serviceAddress';
import { useNavigate } from 'react-router-dom';
import HelpDrawer from '../HelpDrawer';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

const noop = () => {};

const ProgressHeader = ({
  back = noop,
  completionPercentage = 100,
  title,
  hasNav = true,
  showProgressBar = true,
  showBackIcon = true,
  showHelp = true,
}) => {
  const [isHelpDrawerVisible, setIsHelpDrawerVisible] = useState(false);
  const showHelpDrawer = () => {
    console.log('showHelpDrawer');
    setIsHelpDrawerVisible(true);
  };

  const closeHelpDrawer = () => {
    setIsHelpDrawerVisible(false);
  };
 const navigate = useNavigate();
 const location = useLocation();

  const goBack = () => {
    const path = location.pathname;

    console.log("PATH NAME IS:", path);

    // Mapping of the current path to the target path for going back
    const backPathMapping = {
      '/review': '/services',
      '/services': '/house-size',
      '/house-size': '/',
      '/payment': '/review', // Assuming you also want a mapping from payment back to review
      // Add more mappings if needed
    };

    const targetPath = backPathMapping[path];
    if (targetPath) {
      console.log("NAVIGATING TO TARGET PATH:", targetPath);
      navigate(targetPath);
    } else {
      // Handle cases where the mapping does not exist
      console.error('Back navigation not defined for this path:', path);
      // Optionally navigate to a default path or perform another action
      // navigate('/'); // For example, navigate to the home page
    }
  };

  return (
    <>
      <Col span={24} style={{ position: 'relative', marginTop: '16px' }}>
        {showHelp && (
          <QuestionCircleOutlined
            onClick={showHelpDrawer}
            style={{
              fontSize: '24px',
              position: 'absolute',
              top: '50%',
              right: 25,
              transform: 'translateY(-50%)',
              opacity: '0.4',
              pointer: 'cursor',
              zIndex: 1000,
            }}
          />
        )}
        <Col span={24} style={{ textAlign: 'center' }}>
          <img src="/images/digs_logo_color.png" width="80" />
        </Col>
      </Col>

      <Col span={24} style={{ marginTop: '8px' }}>
        {showProgressBar && (
          <div
            style={{ width: '100%', backgroundColor: '#CCF0E5', height: '4px' }}
          >
            <div
              style={{
                width: `${completionPercentage}%`,
                backgroundColor: '#00B47D',
                height: '4px',
              }}
            ></div>
          </div>
        )}
        <ServiceAddress />
      </Col>

      {hasNav && (
        <>
          <Col span={4} style={{ paddingLeft: '24px' }}>
            {showBackIcon && (
              <ArrowLeftOutlined
                style={{
                  fontSize: '20px',
                  color: '#00B47D',
                  strokeWidth: 50,
                  stroke: 'currentColor',
                  marginTop: '16px',
                  verticalAlign: 'middle',
                }}
                onClick={goBack}
              />
            )}
          </Col>
          <Col
            span={16}
            className="fancy-font"
            style={{ textAlign: 'center', marginTop: '16px' }}
          >
            {title}
          </Col>
          <Col span={4}></Col>
        </>
      )}
      <HelpDrawer visible={isHelpDrawerVisible} onClose={closeHelpDrawer} />
    </>
  );
};

export default ProgressHeader;
