export const getExactPropertySize = (selectedUnit) => {
  let propertySize;
  const match = selectedUnit.info.match(/\d{1,3}(?:,\d{3})*(?=\s|\b)/);

  if (match) {
    propertySize = parseInt(match[0].replace(/,/g, ''), 10);
  }

  return propertySize;
};
