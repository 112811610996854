import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Row, Col } from 'antd';
import GoogleAutoComplete from '../Components/googleAutoComplete';
import { useInject } from '../common';
import { CustomerModel } from '../models';
import { PAGES, CUSTOMER_ACTIONS } from '../constants';
import useCustomerNavigation from '../Hooks/NavigationHook';
import ReactGA from 'react-ga4';
import Transition from './Transition';
import useSalesMeet from '../Hooks/MeetHook';
import useUpdateCurrentPage from '../Hooks/CurrentPage';

function Landing() {
  const { referralCode } = useParams();
  const { meetCode } = useSalesMeet();
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isAddressSelected, setIsAddressSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showTransition, setShowTransition] = useState(false);
  const {
    locationService,
    propertyInfoService,
    googleService,
    selfSignupCustomerService,
    servicesService,
    logger,
  } = useInject();
  const customerNavigation = useCustomerNavigation();

  useEffect(() => { }, [selectedAddress]);
  

// useUpdateCurrentPage()

  async function getReferralCode() {
    let validatedReferralCode = '';

    if (referralCode) {
      const isValidReferralCode =
        await servicesService.isValidReferralCode(referralCode);
      validatedReferralCode = isValidReferralCode === true ? referralCode : '';

      if (isValidReferralCode) {
        ReactGA.event({
          category: 'Referral Code',
          action: validatedReferralCode,
        });
      }
    }

    return validatedReferralCode;
  }
  async function validateMeetCode(code) {
    return servicesService.validateMeetCode(code);
  }

  const createCustomerModel = async (market) => {
    const streetView = googleService.getStreetViewImageUrl(
      selectedAddress?.geometry?.location || {},
    );
    const customer = new CustomerModel(
      market,
      selectedAddress.place_id,
      streetView,
      selectedAddress,
      undefined,
      undefined,
      undefined,
      undefined,
      [],
    );
    const propertyDetails =
      await propertyInfoService.getPropertyDetailsByAddress(customer);
    let propertySize = propertyDetails?.livingArea;
    if (!parseInt(propertySize) > 0) {
      propertySize = 0;
    }

    const houseSizes = await propertyInfoService.getHouseSizes(propertySize);
    const tshirtSize = houseSizes.find((x) => x.selected === true).size;

    customer.propertySize = propertySize;
    customer.houseSizes = houseSizes;
    customer.tshirtSize = tshirtSize;
    customer.referralCode = await getReferralCode();
    customer.publicData = propertyDetails;

    logger.log('size based on property sq/ft', `${tshirtSize}/${propertySize}`);

    return customer;
  };



  if (showTransition) {
    return (
      <Transition
        mainText={'Searching'}
        helpText1={"We're finding your home"}
      />
    );
  }

  const checkServiceArea = async () => {
    ReactGA.event({ category: 'Landing Page', action: 'Check Service Area' });
    setIsLoading(true);

    const outcome = await selfSignupCustomerService.verifySubscriptionStatus(
      selectedAddress.place_id,
    );
    if (outcome?.existingSubscription === true) {
      setIsLoading(false);
      const existingCustomer = new CustomerModel(
        'plan-exists',
        undefined,
        undefined,
        selectedAddress,
      );
      existingCustomer.id = 'existing'; //this is a hack because the route guard looks for a set id
      customerNavigation(
        PAGES.PLAN_EXISTS,
        CUSTOMER_ACTIONS.PLAN_EXISTS,
        existingCustomer,
      );
      return;
    } else {
      let action = CUSTOMER_ACTIONS.OUT_OF_AREA;
      let page = PAGES.OUT_OF_AREA;
      const existingWorkflow =
        await selfSignupCustomerService.getCustomerByPlaceId(
          selectedAddress.place_id,
        );
      let model = {};
      var meetCodeUserId = await validateMeetCode(meetCode);
      console.log('meetCodeUserId', meetCodeUserId);
      if (existingWorkflow) {
        logger.log('using existing customer workflow', existingWorkflow.id);
        model = existingWorkflow;
        setIsLoading(false);
        model.welcomeBack = true;
        model.referralCode = await getReferralCode();
        model.meetCode = meetCode;
        model.meetCodeUserId = meetCodeUserId;
        const currentPage = localStorage.getItem("currentPage")
        if (currentPage) {
          page=currentPage
        } else {
        page=PAGES.HOUSE_SIZE
        }
                action = CUSTOMER_ACTIONS.HOUSE_SIZE;

        // page = PAGES.HOUSE_SIZE;
        customerNavigation(page, action, model);
      } else {
        setShowTransition(true);
        const market = await locationService.checkLocationAvailablity(
          selectedAddress?.geometry?.location || {},
        );
        model = await createCustomerModel(market ?? 'out_of_bounds');
        model.meetCode = meetCode;
        model.meetCodeUserId = meetCodeUserId;
        if (market) {
          action = CUSTOMER_ACTIONS.HOUSE_SIZE;
          page = PAGES.HOUSE_SIZE;
          const c = await selfSignupCustomerService.saveCustomerProgress(model);
          model.id = c.id;
        }
        setIsLoading(false);
        customerNavigation(page, action, model);
      }
    }
  };
  return (
    <div style={{ backgroundColor: '#F7F6F2', height:"100vh" }}>
    <Row
      gutter={[16, 16]}
      type="flex"
      justify={'center'}
      style={{
        paddingTop: 32,
        marginRight: 4,
        marginLeft: 4,
        textAlign: 'center',
      }}
    >
      <Col xs={24} lg={8} style={{ textAlign: 'center' }}>
        <img
          src="/images/digs_logo_color.png"
          width="100"
          alt="digs company logo"
        />
      </Col>
      <Col span={24} style={{ fontSize: 34, lineHeight: '35.2px' }}>
        <span className="fancy-font" style={{fontSize:28}}>
          Maintaining your home doesn't need to be frustrating
        </span>
      </Col>
      <Col
        span={24}
        style={{ fontSize: 18, textAlign: 'center', lineHeight: '27px' }}
      >
        <span>Choose your services and Digs handles the rest.</span>
      </Col>
      <Col
        span={24}
        style={{
          textAlign: 'center',
          paddingTop: '16px',
        }}
      >
        <div
          style={{
            color: '#FFFFFF',
            backgroundColor: '#007555',
            paddingTop: '16px',
            paddingBottom: '16px',
            borderRadius: '6px',
            position: 'relative',
            zIndex: 1,
            width: '100%',
            boxSizing: 'border-box',
            paddingRight: 0,
            paddingLeft: 0,
            maxWidth: '400px',
            margin: 'auto',
          }}
        >
          Find out if Digs is available in your area
          <div
            style={{
              position: 'absolute',
              bottom: '-8px',
              left: '50%',
              borderLeft: '16px solid transparent',
              borderRight: '16px solid transparent',
              borderTop: '12px solid #007555',
              transform: 'translateX(-50%)',
              marginBottom: '0px',
            }}
          ></div>
        </div>
      </Col>

      <Col span={24} style={{ marginTop: -8 }}>
        <GoogleAutoComplete
          placeholder="Enter your Address"
          className="google-input-antd"
          onPlaceSelected={(place) => {
            if (place && place.place_id) {
              setSelectedAddress(place);
              setIsAddressSelected(true);
            } else {
              setIsAddressSelected(false);
            }
          }}
        />
      </Col>
      <Col span={24} style={{ marginTop: 4 }}>
        <Button
          type="primary"
          className="main-button"
          style={{ maxWidth: '400px' }}
          disabled={!isAddressSelected || isLoading}
          loading={isLoading}
          onClick={checkServiceArea}
        >
          Check Availability
        </Button>
      </Col>
      </Row>
      </div>
  );
}

export default Landing;
