import React, { useState } from 'react';
import { Col, Card, Select, Button } from 'antd';

const { Option } = Select;

const RoomManager = () => {
  const [rooms, setRooms] = useState([{ id: Date.now() }]);

  const addRoom = () => {
    setRooms((prevRooms) => [...prevRooms, { id: Date.now() }]);
  };

  const removeRoom = (idToRemove) => {
    setRooms((prevRooms) => {
      if (idToRemove === prevRooms[0].id) {
        return prevRooms;
      }
      return prevRooms.filter((room) => room.id !== idToRemove);
    });
  };

  return (
    <div>
      <Col style={{ marginBottom: '15px' }}>
        <Button
          type="dashed"
          style={{ borderColor: '#00b47d', color: '#00b47d' }}
          onClick={addRoom}
        >
          + Add Another Room
        </Button>
      </Col>

      {rooms.map((room, index) => (
        <Card
          size="small"
          key={room.id}
          title={`Room ${index + 1}`}
          extra={
            index !== 0 && (
              <a onClick={() => removeRoom(room.id)} style={{ color: 'red' }}>
                Remove Room
              </a>
            )
          }
          style={{ marginBottom: '15px', textAlign: 'left' }}
        >
          <Select
            placeholder="Select item 1"
            style={{ width: '100%', marginBottom: '10px' }}
          >
            <Option value="item1">Item 1</Option>
            <Option value="item2">Item 2</Option>
          </Select>
          <Select placeholder="Select item 2" style={{ width: '100%' }}>
            <Option value="item1">Item 1</Option>
            <Option value="item2">Item 2</Option>
          </Select>
        </Card>
      ))}
    </div>
  );
};

export default RoomManager;
