import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, Form, Select } from 'antd';

const MultiSelect = ({ service, form }) => {
  const [error, setError] = useState(undefined);
  // this is really stupid but it works
  const [renderId, setRenderId] = useState(Math.random());

  useEffect(() => {
    const { selectedUnits } = form.getFieldsValue();
    if (selectedUnits) {
      form.setFieldsValue({ selectedUnits: service.selectedUnits || [{}] });
    }
  }, []);

  const { options, unitLabel } = service;
  const { areas } = options;

  const onAreaSelect = (index) => {
    const { selectedUnits } = form.getFieldsValue();
    selectedUnits[index].unit = undefined;
    if (selectedUnits) {
      form.setFieldsValue({ selectedUnits: { ...selectedUnits } });
    }
    setRenderId(Math.random());
  };

  return (
    <Fragment>
      <Form.List
        name="selectedUnits"
        rules={[
          {
            validator: async (_, value) => {
              if (!value || value.length === 0) {
                setError('Please add at least one room!');
                return Promise.reject(
                  new Error('Please add at least one room!'),
                );
              } else {
                setError(undefined);
                return Promise.resolve();
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }) => (
          <>
            <Form.Item noStyle>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                style={{
                  marginBottom: '12px',
                  color: '#00b47d',
                  borderColor: '#00b47d',
                }}
              >
                Add Room
              </Button>
            </Form.Item>

            {fields.map((field, index) => {
              const { selectedUnits } = form.getFieldsValue();
              let area = undefined;
              if (selectedUnits) {
                area = areas.find((a) => a.room == selectedUnits[index]?.room);
              }
              return (
                <Card
                  key={field.key}
                  style={{ marginBottom: '12px', textAlign: 'left' }}
                  title={`Room ${index + 1}`}
                  size="small"
                  extra={
                    fields.length > 1 && (
                      <a
                        href="#!" // This is a common placeholder for JavaScript links.
                        onClick={(e) => {
                          e.preventDefault(); // Prevent the default action of the anchor.
                          remove(field.name);
                        }}
                        style={{ color: '#ff4d4f', cursor: 'pointer' }}
                      >
                        Remove Room
                      </a>
                    )
                  }
                >
                  <Form.Item
                    label={'Room'}
                    name={[field.name, 'room']}
                    rules={[{ required: true, message: '*required' }]}
                    noStyle
                  >
                    <Select
                      placeholder="Select Room"
                      style={{ width: '100%', marginBottom: '12px' }}
                      onChange={() => onAreaSelect(index)}
                    >
                      {areas.map((o, idx) => (
                        <Select.Option key={idx} value={o.room}>
                          {o.room}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label={unitLabel}
                    name={[field.name, 'unit']}
                    rules={[{ required: true, message: '*required' }]}
                    noStyle
                  >
                    <Select
                      placeholder={`Select ${unitLabel}`}
                      style={{ width: '100%' }}
                    >
                      {area &&
                        area.selectors.map((o, idx) => (
                          <Select.Option key={idx} value={o.unit}>
                            {o.unit}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Card>
              );
            })}
          </>
        )}
      </Form.List>
      {error && <div style={{ color: 'red' }}>{error}</div>}
    </Fragment>
  );
};

export default MultiSelect;
