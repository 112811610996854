export class LocationService {
  constructor(logger, httpService) {
    this.logger = logger;
    this.httpService = httpService;
  }
  checkLocationAvailablity = async (latlng) => {
    try {
      this.logger?.log('Lat/Long', latlng);
      const result = await this.httpService.post(
        `${process.env.REACT_APP_YEARLY_SUBSCRIPTIONS}/marketcoordinates/checkavailablity`,
        latlng,
      );
      this.logger?.log('checkLocationAvailablity', result);
      return result.data;
    } catch (error) {
      this.logger?.log(error);
      return undefined;
    }
  };
}
