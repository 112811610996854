import React, { useRef, useEffect } from 'react';
import { Row, Col, Card, Checkbox, Button, Input } from 'antd';
import { useInject } from '../common';
import ProgressHeader from '../Components/Styled/ProgressHeader';
import AppleGoogleButtons from '../Components/Styled/AppleGoogleButtons';

const AddressHasPlan = ({ setPage, selectedAddress }) => {
  const spanRef = useRef(null);
  const { logger } = useInject();
  useEffect(() => {
    if (spanRef.current) {
      const width = spanRef.current.offsetWidth;
      spanRef.current.parentNode.style.width = `${width}px`;
    }
  }, []);

  return (
    <Row gutter={[0, 16]}>
      <Col span={24} style={{ textAlign: 'center' }}>
        <ProgressHeader
          hasNav={false}
          showProgressBar={false}
          showHelp={false}
        />
      </Col>
      <Col span={24} style={{ textAlign: 'center', marginTop: '8px' }}>
        <div style={{ display: 'inline-block', position: 'relative' }}>
          <span ref={spanRef} style={{ fontSize: '22px', fontWeight: 400 }}>
            This address already has a plan
          </span>
          <div
            style={{
              fontWeight: 400,
              marginTop: '8px',
              width: '100%',
              wordWrap: 'break-word',
            }}
          >
            Services and schedules can be managed from within the Digs App.
          </div>
        </div>
      </Col>
      <Col span={24}>
        <AppleGoogleButtons />
      </Col>
    </Row>
  );
};

export default AddressHasPlan;
