import React from 'react';
import Autocomplete from 'react-google-autocomplete';
import './googleAutoComplete.css';

function GoogleAutoComplete({ onPlaceSelected, placeholder, className }) {
  return (
    <Autocomplete
      apiKey={process.env.REACT_APP_GOOGLE_MAPS_API}
      onPlaceSelected={onPlaceSelected}
      options={{
        types: ['address'],
        componentRestrictions: { country: 'us' },
      }}
      placeholder={placeholder}
      className={className}
    />
  );
}

export default GoogleAutoComplete;
