import React, { Fragment, useState } from 'react';
import { Row, Col, Checkbox } from 'antd';
import { formatMoney } from '../../Helpers/formatters';
import { generateDisplayText } from '../../Helpers/generateDisplayText';

const ServiceItem = ({ service, childServices }) => {
  const price =
    service.price +
    (childServices.length > 0
      ? childServices.reduce((acc, s) => acc + s.price, 0)
      : 0);

  return (
    <Fragment>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '12px',
          borderBottom: '1px solid #f0f0f0',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span style={{ fontWeight: 500 }}>
            {service.name}
            <span
              style={{
                fontStyle: 'italic',
                opacity: '0.6',
                marginLeft: '4px',
                fontSize: '12px',
              }}
            >
              {service.name !== 'Digs Annual Membership' && price
                ? `${formatMoney(price)} /yr`
                : ''}
            </span>
          </span>
          <span style={{ fontWeight: 'bold' }}>
            {price ? formatMoney(price / 12) : 'TBD'}
          </span>
        </div>
        {service.name === 'Digs Annual Membership' ? (
          <span style={{ maxWidth: '80%', opacity: '0.7' }}>
            All your home maintenance under one app with automated scheduling
            and one simple monthly bill.
          </span>
        ) : (
          <>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span style={{ opacity: '0.7' }}>
                {price
                  ? generateDisplayText(service, childServices)
                  : 'Price set after consultation'}
              </span>
              <span></span>
            </div>
            <div style={{ opacity: '0.7' }}>
              {price
                ? `${service.perYear} visits per year`
                : 'Consultation to be scheduled within 2 weeks'}
            </div>
          </>
        )}
      </div>
    </Fragment>
  );
};

const DisplayServices = ({ services }) => {
  const displayServices = services.filter((s) => !s.parentId);

  const ServiceList = () => {
    return displayServices.map((service) => {
      const childServices = services.filter(
        (s) => s.parentId === service.serviceId,
      );
      return (
        <Col span={24} key={service.serviceId}>
          <div>
            <ServiceItem service={service} childServices={childServices} />
          </div>
        </Col>
      );
    });
  };

  return (
    <Row gutter={[]} type="flex" justify="center" style={{}}>
      <ServiceList />
    </Row>
  );
};

export default DisplayServices;
