import ProgressHeader from './Styled/ProgressHeader';
import { App, Col, Row, Form, Input, Button, Drawer } from 'antd';
import { useEffect, useState } from 'react';
import { useMobile, useTablet, useDesktop } from '../Helpers/responsive';
import { useCustomer, useCustomerDispatch } from '../common/customerContext';
import useCustomerNavigation from '../Hooks/NavigationHook';
import { useForm, useWatch } from 'antd/es/form/Form';
import { CONFIGURATION, CUSTOMER_ACTIONS } from '../constants';
import { useInject } from '../common';
import CustomerForm from './customerForm';
import { EmailModel } from '../models';

const HelpDrawer = ({ visible, onClose }) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const isMobile = useMobile();
  const isTablet = useTablet();
  const isDesktop = useDesktop();
  const [form] = useForm();
  const customer = useCustomer();
  const dispatch = useCustomerDispatch();
  const [submittable, setSubmittable] = useState(false);
  const { selfSignupCustomerService, communicationService } = useInject();
  const getDrawerPlacement = () => {
    if (isMobile) return 'bottom';
    return 'right';
  };

  const getDrawerHeight = () => {
    if (isMobile) return '90%';
    return '75%';
  };

    useEffect(() => {
      // If you want to set or reset the form values every time the drawer is opened or customer data changes
      if (customer && form) {
        form.resetFields(); // Reset the form to clear any previous values
        form.setFieldsValue({
          firstName: customer?.firstName,
          lastName: customer?.lastName,
          phoneNumber: customer?.phoneNumber,
          emailAddress: customer?.emailAddress,
        });
      }
    }, [customer, form, visible]);

  const handleFormSubmit = async (values) => {
    dispatch(CUSTOMER_ACTIONS.HELP, values);
    try {
      await selfSignupCustomerService.saveCustomerProgress(customer);
      await communicationService.sendEmail(
        new EmailModel(
          CONFIGURATION.SUPPORT_EMAIL,
          `${customer.firstName} ${customer.lastName} Needs Your Help!`,
          ` ${customer.firstName} ${customer.lastName} ${customer.phoneNumber} ${customer.emailAddress}`,
        ),
      );
    } catch {
    } finally {
      setIsSubmitted(true);
    }
  };
  const values = useWatch([], form);
  useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        },
      );
  }, [values]);

  return (
    <Drawer
      title="Help"
      className="custom-drawer"
      onClose={onClose}
      open={visible}
      maskClosable={false}
      placement={getDrawerPlacement()}
      height={getDrawerHeight()}
      width={isTablet || isDesktop ? '550px' : undefined}
      style={{
        backgroundColor: '#F7F6F2', // Set the background color to orange
        ...(isMobile
          ? { borderTopLeftRadius: '15px', borderTopRightRadius: '15px' }
          : {}),
      }}
    >
      <Row type="flex" justify="center" style={{}}>
        {isSubmitted ? (
          <>
            <Col
              align="center"
              xs={24}
              style={{ marginTop: '32px' }}
              className="padMobile fancy-font"
            >
              Message Received
            </Col>

            <Col
              align="center"
              xs={24}
              style={{ marginTop: '16px' }}
              className="padMobile"
            >
              We received your message and one of our Homeowner Support members
              will contact you to answer any questions or to help you build a
              plan that’s right for your home. Hang tight and we’ll contact you
              at the following number asap.
            </Col>

            <Col
              align="center"
              xs={24}
              style={{ marginTop: '32px', fontSize: '20px' }}
              className="padMobile"
            >
              {customer?.firstName} {customer?.lastName}
            </Col>

            <Col
              align="center"
              xs={24}
              style={{ marginTop: '8px', fontSize: '20px' }}
              className="padMobile"
            >
              {customer?.phoneNumber}
            </Col>
          </>
        ) : (
          <>
            <Col
              align="center"
              xs={24}
              style={{ marginTop: '32px' }}
              className="padMobile fancy-font"
            >
              Need Help?
            </Col>

            <Col
              align="center"
              xs={24}
              style={{ marginTop: '16px' }}
              className="padMobile"
            >
              Have questions or just need help putting together a plan? No
              worries, we’re here to help! Just enter your contact information
              below and we’ll reach out as soon as we can to assist.
            </Col>

            <Col
              align="left"
              xs={24}
              style={{ marginTop: '24px' }}
              className="padMobile"
            >
              <Form
                autoComplete="on"
                form={form}
                layout="vertical"
                onFinish={handleFormSubmit}
              >
                <CustomerForm action={CUSTOMER_ACTIONS.HELP}></CustomerForm>
                <Form.Item>
                  <div style={{ textAlign: 'center' }}>
                    <Button
                      className="main-button"
                      style={{ marginBottom: '48px', marginTop: '32px' }}
                      type="primary"
                      htmlType="submit"
                      disabled={!submittable}
                    >
                      Request Help
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </Col>
          </>
        )}
      </Row>
    </Drawer>
  );
};

export default HelpDrawer;
