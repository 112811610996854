import { Col, Row, Form, Button } from 'antd';
import React, { Fragment, useState, useEffect } from 'react';
import { useForm, useWatch } from 'antd/es/form/Form';
import { useInject } from '../common';
import { useCustomer, useCustomerDispatch } from '../common/customerContext';
import ProgressHeader from '../Components/Styled/ProgressHeader';
import { CUSTOMER_ACTIONS, PAGES } from '../constants';
import CustomerForm from '../Components/customerForm';
import { useNavigate } from 'react-router-dom';

const CustomerDetails = () => {
  const navigation = useNavigate();
  const { selfSignupCustomerService } = useInject();
  const [form] = useForm();
  const customer = useCustomer();
  const { firstName, lastName, emailAddress, phoneNumber } = customer;
  const dispatch = useCustomerDispatch();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submittable, setSubmittable] = useState(false);
  const values = useWatch([], form);
  const initialValues = {
    firstName,
    lastName,
    emailAddress,
    phoneNumber,
  };
  useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        },
      );
  }, [values]);
  /***
   * when the form is submitted, we need to create a cart if it doesn't already exist and
   * populate the customer information.
   */
  const handleFormSubmit = async (values) => {
    dispatch(CUSTOMER_ACTIONS.MEET_CUSTOMER_DETAILS, values);
    try {
      await selfSignupCustomerService.saveCustomerProgress(customer);
      navigation(`/${PAGES.SERVICES}`);
    } catch {
    } finally {
      setIsSubmitted(true);
    }
  };

  return (
    <Fragment>
      <Row type="flex" justify="center">
        <ProgressHeader
          title="Enter Contact Information!"
          completionPercentage={50}
          showBackIcon={false}
          hasNav={true}
        />

        <Row gutter={[0, 16]} style={{ width: '100%' }}>
          <Col xs={0} md={5} lg={6}></Col>
          <Col
            xs={24}
            md={14}
            lg={12}
            style={{ marginTop: '16px' }}
            className="padMobile"
          ></Col>
          <Col xs={0} md={5} lg={6}></Col>

          {/* 
        <Col xs={0} md={5} lg={6}></Col>
        <Col
          xs={24} md={14} lg={12}
          style={{ fontSize: "14px",marginTop:"16px",marginBottom:"16px", fontWeight: 600 }}
          className="padMobile"
        >
          Payment Information
        </Col>
        <Col xs={0} md={5} lg={6}></Col> */}

          <Col xs={0} md={5} lg={6}></Col>
          <Col
            xs={24}
            md={14}
            lg={12}
            style={{
              fontSize: '14px',
              marginTop: '16px',
              fontWeight: 500,
            }}
            className="padMobile"
          >
            <Form
              autoComplete="on"
              form={form}
              layout="vertical"
              onFinish={handleFormSubmit}
              initialValues={initialValues}
            >
              <CustomerForm
                action={CUSTOMER_ACTIONS.MEET_CUSTOMER_DETAILS}
              ></CustomerForm>
              <Form.Item>
                <div style={{ textAlign: 'center' }}>
                  <Button
                    className="main-button"
                    style={{ marginBottom: '48px', marginTop: '12px' }}
                    type="primary"
                    htmlType="submit"
                    disabled={!submittable}
                  >
                    Next
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Col>
          <Col xs={0} md={5} lg={6}></Col>
        </Row>
      </Row>
    </Fragment>
  );
};

export default CustomerDetails;
