import React from 'react';
import { Drawer, Row, Col, Card, Modal } from 'antd';
import ServiceSettings from './ServicesConfig/ServiceSettings';
import { useMobile, useTablet, useDesktop } from '../Helpers/responsive';
import Counter from './Styled/Counter';
import RoomManager from './Styled/RoomManager';
import { useState } from 'react';

const ServiceDrawer = ({ visible, service, children, onClose, onComplete }) => {
  const isMobile = useMobile();
  const isTablet = useTablet();
  const isDesktop = useDesktop();

  const getDrawerPlacement = () => {
    if (isMobile) return 'bottom';
    return 'right';
  };

  const getDrawerHeight = () => {
    if (isMobile) return '90%';
    return '75%';
  };

  return (
    <Drawer
      placement={getDrawerPlacement()}
      className="custom-drawer"
      closable={true}
      onClose={onClose}
      open={visible}
      destroyOnClose={true}
      height={getDrawerHeight()}
      width={isTablet || isDesktop ? '550px' : undefined}
      style={{
        backgroundColor: '#F7F6F2', // Set the background color to orange
        ...(!isDesktop
          ? { borderTopLeftRadius: '15px', borderTopRightRadius: '15px' }
          : {}),
      }}
    >
      <Row justify="center" align="middle">
        <Col xs={24} style={{ textAlign: 'center' }}>
          <ServiceSettings
            service={service}
            onComplete={onComplete}
            children={children}
          />
        </Col>
      </Row>
    </Drawer>
  );
};

export default ServiceDrawer;
