export const ATLANTA_MARKET_ID = `c4fe746b-2239-4cee-b8a4-270e7a079518`;

export const PAGES = {
  HOME: 'home',
  SERVICES: 'services',
  PAYMENT: 'payment',
  CART: 'cart/:subscriptionId',
  CART_NOT_FOUND: 'cart-not-found',
  OUT_OF_AREA: 'out-of-area',
  ADDRESS_HAS_PLAN: 'address-has-plan',
  SQUARE_FOOT: 'square-foot',
  REVIEW: 'review',
  HOUSE_SIZE: 'house-size',
  REFERRAL_CODE: '/r/:referralCode',
  PLAN_EXISTS: 'plan-exists',
  SUCCESS: 'success',
  MEET_PARAMETERIZED: '/meet/:meetCode',
  MEET_DEFAULT: '/meet',
  MEET_CUSTOMER_DETAILS: '/customer',
};
export const CUSTOMER_ACTIONS = {
  SET_TSHIRT_SIZE: 'SET_TSHIRT_SIZE',
  HOME: 'home',
  SERVICES: 'services',
  PAYMENT: 'payment',
  OUT_OF_AREA: 'out-of-area',
  ADDRESS_HAS_PLAN: 'address-has-plan',
  SQUARE_FOOT: 'square-foot',
  REVIEW: 'review',
  HOUSE_SIZE: 'house-size',
  HELP: 'help',
  COMPLETE: 'complete',
  LOCAL_SESSION_KEY: 'digscustomer',
  PLAN_EXISTS: 'plan-exists',
  UPDATE_SERVICES: 'update-services',
  MEET_CUSTOMER_DETAILS: 'meet-update-customer',
  CART: 'set-cart',
  LEADGEN:'leadgen'
};
export const CUSTOMER_STATUS = {
  WAITLIST: 'waitlist',
};

export const CONFIGURATION = {
  SUPPORT_EMAIL: 'support@digs.company',
};

export const HOUSE_WASH_PRICE = 0.105; // 10 and half cents
