import React, { useEffect } from 'react';
import './App.css';
import './index.css';
import { antdTheme } from './AntdTheme';
import { ConfigProvider } from 'antd';
import { ContainerProvider, registry } from './common';
import { CustomerProvider } from './common/customerContext';
import AppRouter from './common/router';
import ReactGA from 'react-ga4';

const trackingId = process.env.REACT_APP_GOOGLE_GA_KEY;
ReactGA.initialize(trackingId);

function App() {
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }, []);

  return (
    <ContainerProvider container={registry}>
      <ConfigProvider theme={antdTheme}>
        <div className="App">
          <CustomerProvider>
            <AppRouter />
          </CustomerProvider>
        </div>
      </ConfigProvider>
    </ContainerProvider>
  );
}

export default App;
