import axios from 'axios';

export class HttpService {
  constructor(logger) {
    this.logger = logger;
    this.axios = axios.create();
    this.axios.interceptors.request.use(
      this.onRequestSuccess.bind(this),
      this.onRequestFailed.bind(this),
    );
    this.axios.interceptors.response.use(
      this.onResponseSuccess.bind(this),
      this.onResponseFailed.bind(this),
    );
  }
  get client() {
    return this.axios;
  }

  onRequestSuccess(config) {
    return config;
  }
  onRequestFailed(error) {
    this.logger.log('http-request-error', error);
    // Do something with request error
    return Promise.reject(error);
  }
  onResponseSuccess(response) {
    return response;
  }
  onResponseFailed(error) {
    this.logger.log('http-response-error', error);
    return Promise.reject(error);
  }

  get(url, params) {
    return this.axios.get(url, {
      params: params,
    });
  }
  post(url, data) {
    return this.axios.post(url, data);
  }
  patch(url, data) {
    return this.axios.patch(url, data);
  }
}
