import { Button, Col, Row, Modal } from 'antd';
import React, { Fragment, useState } from 'react';
import ConfigureService from './ConfigureService';
import { useForm } from 'antd/es/form/Form';
import { useMobile, useTablet, useDesktop } from '../../Helpers/responsive';
import ServiceInputsV2 from './ServiceInputsV2';
import { getExactPropertySize } from '../../Helpers/getPropertySize';

const ServiceSettings = ({ service, children, onComplete }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = useForm();

  const isNotHouseWashOrExactTshirtSize = (service) =>
    service.name !== 'House Wash' &&
    service.selectedUnit !== undefined &&
    service.selectedUnit.tshirtSize !== undefined &&
    service.selectedUnit.tshirtSize !== 'exact';

  const isHouseWashAndExactTshirtSize = (service) =>
    service.name === 'House Wash' &&
    service.selectedUnit.tshirtSize !== undefined &&
    service.selectedUnit.tshirtSize === 'exact';

  const isHouseWashButNotExactTshirtSize = (service) =>
    service.name === 'House Wash' &&
    service.selectedUnit.tshirtSize !== undefined &&
    service.selectedUnit.tshirtSize !== 'exact';

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const isMobile = useMobile();

  const isServiceSelected = service.included;

  const handlev1ServiceConfig = (data) => {
    const childKeys = Object.keys(data).reduce((acc, key) => {
      if (key.includes('child')) {
        acc.push(key);
      }
      return acc;
    }, []);
    service = handleServiceUpdate(service, data);
    onComplete(service);
    if (childKeys.length > 0) {
      childKeys.forEach((key) => {
        const serviceId = key.split('child-')[1];
        let childService = children.find((c) => c.serviceId === serviceId);
        const childServiceData = data[key];
        childService = handleChildServiceUpdate(childService, childServiceData);
        onComplete(childService);
      });
    }
  };

  const setServiceData = (service, data) => {
    const { unit, quantity } = data;

    if (unit && unit !== 'individual') {
      const selectedUnit = service.options.selectors.find(
        (s) => s.unit === unit,
      );
      service.setSelectedUnit(selectedUnit);
    } else if (quantity !== undefined) {
      service.setSelectedUnit({ ...service.options, quantity });
    }
    return service;
  };

  const handleChildServiceUpdate = (service, data) => {
    service = setServiceData(service, data);
    if (service.selectedUnit?.quantity) {
      service.included = true;
    } else {
      service.included = false;
    }
    return service;
  };
  const handleServiceUpdate = (service, data) => {
    service = setServiceData(service, data);
    service.included = true;
    return service;
  };

  const onFormComplete = (data) => {
    if (service.version !== 2) {
      handlev1ServiceConfig(data);
    } else {
      data.included = true;
      onComplete(data);
    }
  };

  const handleRemoveFromPlan = (event) => {
    event.preventDefault();
    service.included = false;
    onComplete(service);
  };

  const handleFormSubmit = () => {
    form.submit();
  };

  const filterServiceSelectors = (service) => {
    if (service.name === 'House Wash') {
      service.options.selectors = service.options.selectors.filter(
        (s) => s.tshirtSize !== 'exact',
      );
    }

    return service;
  };

  const renderServiceQuestion = (service) => {
    if (
      isNotHouseWashOrExactTshirtSize(service) ||
      isHouseWashButNotExactTshirtSize(service)
    ) {
      return service.question;
    } else if (isHouseWashAndExactTshirtSize(service)) {
      return `Your home size is ${getExactPropertySize(
        service.selectedUnit,
      ).toLocaleString()} sq ft`;
    }

    return service.question;
  };

  const ServiceForm = ({ service, form, onComplete, isChild }) => {
    return (
      <Fragment>
        <Col
          span={24}
          className="service-drawer-title"
          style={{ marginBottom: '16px' }}
        >
          {!service.parentId && service.name}
        </Col>

        {service.infoBlurb && (
          <Col span={24} style={{ marginBottom: '16px' }}>
            {service.infoBlurb}
          </Col>
        )}

        <Col
          span={24}
          className="service-drawer-question"
          style={{ marginBottom: '16px' }}
        >
          {renderServiceQuestion(service)}
        </Col>

        {!service.price &&
          (service.name === 'Landscape Maintenance' ||
            service.name === 'Pool Maintenance') && (
            <Col span={24} style={{ marginBottom: '16px' }}>
              <div
                className="light-gray-card"
                style={{
                  padding: '12px',
                  height: '100px',
                  borderRadius: '8px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  opacity: 0.8,
                }}
              >
                <div style={{ fontWeight: 600, marginBottom: '16px' }}>
                  Consultation Required...
                </div>
                <div>
                  A technician will visit your location, assess the specifics,
                  and provide a tailored price, ensuring you only pay for the
                  services you truly need.
                </div>
              </div>
            </Col>
          )}

        {service.version === 2 ? (
          <ServiceInputsV2
            service={service}
            form={form}
            onComplete={onComplete}
            isChild={isChild}
          />
        ) : (
          <ConfigureService
            form={form}
            service={filterServiceSelectors(service)}
            onFormComplete={onComplete}
            isChild={isChild}
          />
        )}
      </Fragment>
    );
  };
  return (
    <Fragment>
      <Row gutter={[0, 16]} type="flex" justify="center">
        <Col span={24}>
          <ServiceForm
            service={service}
            form={form}
            onComplete={onFormComplete}
          />
        </Col>
        {children &&
          children.map((c, i) => {
            return (
              <Col span={24} key={i}>
                <ServiceForm
                  service={c}
                  form={form}
                  onComplete={onFormComplete}
                  isChild={true}
                />
              </Col>
            );
          })}
        <Col span={24} style={{ marginTop: '16px' }}>
          <Button
            className={`main-button`}
            type="primary"
            onClick={handleFormSubmit}
          >
            {!isServiceSelected ? 'ADD TO PLAN' : 'UPDATE PLAN'}
          </Button>{' '}
          <div style={{ marginTop: '16px', color: '#FF0000' }}>
            <a
              href="#"
              style={{ color: '#FF0000' }}
              onClick={handleRemoveFromPlan}
            >
              Remove from Plan
            </a>
          </div>
        </Col>
      </Row>
      <Modal
        title={`INFO ABOUT ${service.name}`}
        open={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        className={isMobile ? 'fullscreen-modal' : ''}
      >
        ENTER DATA HERE FOR SERVICE INFO
      </Modal>
    </Fragment>
  );
};

export default ServiceSettings;
