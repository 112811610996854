import { Button, Card, Col, Row, Radio, Drawer, Form } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useForm } from 'antd/es/form/Form';
import { useInject } from '../common';
import {
  CustomerContext,
  useCustomer,
  useCustomerDispatch,
} from '../common/customerContext';
import ServiceAddress from '../Components/Address/serviceAddress';
import { useMobile, useTablet, useDesktop } from '../Helpers/responsive';
import { CUSTOMER_ACTIONS, CUSTOMER_STATUS, PAGES } from '../constants';
import useCustomerNavigation from '../Hooks/NavigationHook';
import ProgressHeader from '../Components/Styled/ProgressHeader';
import CustomerForm from '../Components/customerForm';
import useUpdateCurrentPage from '../Hooks/CurrentPage';

const HouseSize = ({ address, marketId }) => {
  const isMobile = useMobile();
  const isTablet = useTablet();
  const isDesktop = useDesktop();

  const [form] = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { servicesService, selfSignupCustomerService } = useInject();
  const completionPercentage = 25;
  const [isContentLong, setIsContentLong] = useState(false);
  const customer = useCustomer();
  const dispatch = useCustomerDispatch();
  const [propertySize] = useState(customer?.propertySize);
  const [selectedPropertySize, setSelectedPropertySize] = useState(
    customer?.tshirtSize,
  );
  const [houseSizes] = useState(customer?.houseSizes);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const customerNavigation = useCustomerNavigation();

  const toggleDrawer = () => {
    setIsDrawerVisible(!isDrawerVisible);
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const handleImageError = () => {
    setImageLoaded(false);
    setImageError(true);
  };

  useUpdateCurrentPage()

  // console.log("customer is ", customer);

  const submitForm = async () => {
    try {
      await form.validateFields();
      setIsLoading(true);
      customer.formFilled = true
      await selfSignupCustomerService.saveCustomerProgress(customer);
setIsLoading(false);
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const onSelectPropertySizeChanged = ({ target: { value } }) => {
    dispatch(CUSTOMER_ACTIONS.SET_TSHIRT_SIZE, value);
    setSelectedPropertySize(value);
  };
  const loadServices = async () => {
    setIsLoading(true);
    const data = await servicesService.getServices(customer.marketId, customer);
    customerNavigation(PAGES.SERVICES, CUSTOMER_ACTIONS.SERVICES, data, () => {
      if (customer?.meetCode?.length > 1) {
        return PAGES.MEET_CUSTOMER_DETAILS;
      }
    });
    delete customer.propertySizeChangedCount;
    await selfSignupCustomerService.saveCustomerProgress(customer);
    setIsLoading(false);
  };
  const saveHomeSize = async () => {
    customer.tshirtSize = selectedPropertySize;
    dispatch(CUSTOMER_ACTIONS.SET_TSHIRT_SIZE, selectedPropertySize);
    setIsDrawerVisible(false);
    await loadServices();
  };



  return (
    <Fragment>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          backgroundColor: '#F7F6F2',
        }}
      >
        <Row
          type="flex"
          justify="center"
          style={{ backgroundColor: '#F7F6F2' }}
        >
          <ProgressHeader
            completionPercentage={completionPercentage}
            showBackIcon={false}
            hasNav={false}
          />
          <Col span={24} style={{ textAlign: 'center' }}>
            {customer.google_street_view && propertySize > 0 && !imageError && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    height: isMobile ? '250px' : isTablet ? '300px' : '400px',
                    borderRadius: '4px',

                    overflow: 'hidden',
                  }}
                >
                  <img
                    src={customer.google_street_view}
                    style={{
                      width: '100%',
                      height: '100%',
                      display: imageLoaded ? 'block' : 'none',
                      objectFit: 'cover',
                      objectPosition: 'center calc(50% - 20px)',
                    }}
                    alt="google-street-view"
                    onLoad={handleImageLoad}
                    onError={handleImageError}
                  />
                </div>
              </div>
            )}

            <div
              className={
                customer.google_street_view && propertySize > 0 && !imageError
                  ? 'curveDiv'
                  : ''
              }
            >
              {customer.google_street_view &&
                propertySize > 0 &&
                !imageError && (
                  <CheckCircleFilled
                    style={{
                      fontSize: '24px',
                      color: '#00B47D',
                      top: '-50px',
                      position: 'relative',
                      background: 'white',
                      borderRadius: '50%',
                      border: '5px solid #F7F6F2',
                    }}
                  />
                )}
            </div>
          </Col>

          <Row
            gutter={[16, 16]}
            type="flex"
            justify={'center'}
            style={{ marginRight: 8, marginLeft: 8 }}
          >
            <Col
              span={24}
              style={{
                textAlign: 'center',
                marginTop:
                  customer.google_street_view && propertySize > 0 && !imageError
                    ? '-65px'
                    : '16px',
                zIndex: 100,
              }}
            >
              <div className="fancy-font" style={{ fontSize: '28px' }}>
                {customer.welcomeBack === true ? (
                  <>
                    <span className="fancy-font">Welcome Back!</span>
                    <br />
                    <span className="fancy-font">We're in your area</span>
                  </>
                ) : (
                  <>
                    Nice!
                    <br />
                    We're in your area
                  </>
                )}
              </div>
            </Col>

            <Col
              span={24}
              style={{
                textAlign: 'center',
                zIndex: 100,
                fontSize: '16px',
                lineHeight: '1.5',
              }}
            >
              {propertySize > 0 ? (
                <Fragment>
                  Based on your address. It looks like <br /> your home is{' '}
                  <span style={{ color: '#00b47d', fontWeight: 600 }}>
                    {propertySize?.toLocaleString()}
                  </span>{' '}
                  sq ft. <br /> Sound right?
                </Fragment>
              ) : (
                <Fragment>
                  Get started by selecting a home size from the list below. This
                  helps us provide a more tailored annual maintenance plan.
                </Fragment>
              )}
            </Col>

            {!propertySize && (
              <>
                <Col xs={24} md={14} lg={12} style={{ textAlign: 'center' }}>
                  <Radio.Group
                    style={{ width: '100%' }}
                    value={selectedPropertySize}
                    onChange={onSelectPropertySizeChanged}
                  >
                    {houseSizes.map((x) => {
                      return (
                        <label className="input-box-radio" key={x.size}>
                          <Radio value={x.size}>
                            <span style={{ fontWeight: 600 }}>{x.title}</span>
                          </Radio>
                        </label>
                      );
                    })}
                  </Radio.Group>
                </Col>
              </>
            )}

            <Col
              span={24}
              style={
                isContentLong
                  ? { marginBottom: '32px', textAlign: 'center' }
                  : { textAlign: 'center' }
              }
            >
              <Button
                className={`main-button`}
                style={{ maxWidth: '300px' }}
                type="primary"
                onClick={async () => await loadServices()}
              >
                {!propertySize ? 'Next' : 'Yes - that sounds correct'}
              </Button>
            </Col>
            {propertySize > 0 && (
              <Col span={24} style={{ textAlign: 'center', marginTop: '16px' }}>
                <a onClick={toggleDrawer}>Hmm... that seems off</a>
              </Col>
            )}
          </Row>
        </Row>
        <Drawer
          placement={isDesktop ? 'right' : 'bottom'}
          closable={true}
          onClose={toggleDrawer}
          open={isDrawerVisible}
          width={isDesktop ? 500 : '100%'}
          height={'95%'}
          className={`custom-drawer ${!isDesktop ? 'round-top' : ''}`}
          style={{ backgroundColor: '#F7F6F2' }}
        >
          <Row gutter={[16, 16]} type="flex" justify={'center'}>
            <Col span={24} style={{ textAlign: 'center' }}>
              <div className="drawer-title">Home Size</div>
            </Col>
            <Col span={24} style={{ textAlign: 'center' }}>
              In order to make helpful suggestions about your annual maintenance
              plan we use public information about your property. Sometimes that
              information can be wrong or outdated, especially if your home has
              had an addition or upgrades made.
            </Col>

            <Col span={24} style={{ textAlign: 'center' }}>
              If you believe that the{' '}
              <span style={{ fontWeight: 600 }}>
                {propertySize.toLocaleString()}
              </span>{' '}
              sq ft looks off, no worries, you can select a home size from the
              list below to help us provide a more tailored annual maintenance
              plan.
            </Col>
            <Col style={{}} xs={24}>
              <Radio.Group
                style={{ width: '100%', marginTop: '16px' }}
                value={selectedPropertySize}
                onChange={onSelectPropertySizeChanged}
              >
                {houseSizes.map((x) => {
                  return (
                    <label className="input-box-radio" key={x.size}>
                      <Radio value={x.size}>
                        <span style={{ fontWeight: 600 }}>{x.title}</span>
                      </Radio>
                    </label>
                  );
                })}
              </Radio.Group>
            </Col>
            <Col span={24} style={{ textAlign: 'center', marginTop: '16px' }}>
              <Button
                className="main-button"
                type="primary"
                onClick={saveHomeSize}
              >
                Save Home Size
              </Button>
            </Col>
          </Row>
        </Drawer>
      </div>
    </Fragment>
  );
};

export default HouseSize;
