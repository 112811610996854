import React, { Fragment, useState, useEffect } from 'react';
import HouseBuild from '../Components/Lottie/HouseBuild';

const Transition = ({ mainText, helpText1, helpText2 }) => {
  const [animationState, setAnimationState] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimationState((prevAnimationState) => {
        if (prevAnimationState === 3) {
          return 0;
        } else {
          return prevAnimationState + 1;
        }
      });
    }, 1000);

    return () => {
      clearInterval(interval);
      // customerNavigation(PAGES.REVIEW, PAGES.REVIEW, customer);
    };
  }, []);

  const renderPeriods = () => {
    const periods = '.'.repeat(animationState);
    return periods || '\u00A0';
  };

  return (
    <Fragment>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
        }}
      >
        <div style={{ textAlign: 'center', marginTop: '-48px' }}>
          <HouseBuild />
        </div>
        <div
          style={{
            marginTop: '-46px',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '20px',
            }}
          >
            <span>{mainText}</span>
            <span
              className="periods-container"
              style={{
                minWidth: '30px',
                textAlign: 'left',
                overflow: 'visible',
              }}
            >
              {renderPeriods()}
            </span>
          </div>
          <div style={{ textAlign: 'center' }}>
            {helpText1}
            <br /> {helpText2}
          </div>
        </div>
        <div
          style={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: '18px',
            marginTop: '12px',
          }}
        ></div>
      </div>
    </Fragment>
  );
};

export default Transition;
